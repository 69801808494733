import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, shape, string } from 'prop-types';
import Form from '../../Form';
import DataSources from '../../DataSources';
import Data from '../../Data';
import Page from '../../Page';
import { awsAccountIdRegex } from '../../../utils/regex';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import { roleCreationMethods, glueS3RoleMethods } from '../constants';
import { required, requiredMultipleSelect, patternIAMRole } from '../utils';
import Help from './GlueConnectionDetailsHelp';
import { CloudFormationHelpDiv } from './styledComponents';

const display = {
  formContentHeader: 'Connection Configuration',
  formExplaination: 'Secondary explanation',
  regionLabel: 'AWS Region',
  credentialTypeLabel: 'Glue Credentials',
  roleLabel: 'S3 Role ARN',
  roleRequiredError: 'S3 role ARN is required',
  roleARNPatternError:
    'Role ARN should be in the format of "arn:aws:iam::<AWS ACCOUNT ID>:role/<ROLE NAME>"',
  glueRoleLabel: 'Glue Role ARN',
  glueRoleRequiredError: 'Glue role ARN is required',
  rolesCreationMethodLabel: 'Roles Setup',
  rolesSectionTitle: 'IAM Roles',
  rolesSectionExplanation:
    'Choose a method for setting up the necessary AWS IAM roles. We recommend using CloudFormation.',
  cloudFormationLabel: 'CloudFormation Quick Create',
  cloudFormationButtonLabel: 'Open CloudFormation',
  cloudFormationFinalHelp:
    // eslint-disable-next-line quotes
    "Once the CloudFormation stack is created, go to the 'Outputs' tab and copy the value of the IBMCloudDataSourceGlueS3RoleArn Key. Paste this value in the 'Glue/S3 Role ARN' field below. ",
  glueS3RoleLabel: 'Glue/S3 Role ARN',
  glueCatalogIdLabel: 'Glue Data Catalog ID',
  glueCatalogIdMaxChar: 'This value can be a max of 12 characters',
  glueCatalogIdIllegalCharacters:
    'This value is required to be a 12-digit number',
  awsLakeFormationHeader: 'AWS Lake Formation',
  awsLakeFormationLabel: 'Enable AWS Lake Formation',
  lakeFormationSectionTitle: 'IBM watsonx.data Integration Configuration',
  lakeFormationSectionExplanation:
    'Lake Formation needs to be configured to integrate with watsonx.data. You can do this manually, or we can do it on your behalf.',
  lakeFormationSetupLabel: 'Data Lake Settings',
  lakeFormationCFButtonLabel: 'Open CloudFormation',
  authorizationHeader: 'Authorization',
  authorizationLabel: 'Authorization Service',
};

const roleCreationMethodValues = [
  {
    label: 'CloudFormation',
    value: roleCreationMethods.cloudFormation,
  },
  {
    label: 'Manual',
    value: roleCreationMethods.manual,
  },
];

const s3RoleMethodValues = [
  {
    label: 'Same as Glue role',
    value: glueS3RoleMethods.sameAsGlue,
  },
  {
    label: 'Use different role',
    value: glueS3RoleMethods.custom,
  },
];

const type = DataSources.constants.dataSourceTypes.glue;

const ifNotLakeFormation = (getValues, additionalValidation) => {
  return (value) => {
    const lakeFormationChecked = getValues('glue.security');
    if (lakeFormationChecked) return true;
    return additionalValidation(value);
  };
};

const GlueConnectionDetails = ({
  disabled,
  active,
  awsRegions,
  computePlaneRegion,
  awsGlueS3CloudFormationQuickCreateUrl,
  awsLakeFormationCloudFormationQuickCreateUrl,
  dataSource,
  unsupportedLakeFormationPrestoClusters,
  unsupportedRangerHivePrestoClusters,
  authorizationServices,
}) => {
  const { getValues, watch, clearErrors } = useFormContext();

  useEffect(() => {
    if (!active) {
      clearErrors('glue');
    }
  }, [active, clearErrors]);

  const isLakeFormation = DataSources.utils.isLakeFormation(dataSource);
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const useDifferentRole =
    dataSource &&
    !isLakeFormation &&
    dataSource.connection.hive['metastore.glue']['iam-role'] !==
      dataSource.connection.hive?.s3?.['iam-role'];

  const roleCreationMethod = watch(
    'glue.roleCreationMethod',
    (useDifferentRole && roleCreationMethodValues[1].value) ||
      roleCreationMethodValues[0].value,
  );

  const s3RoleMethod = watch(
    'glue.s3RoleMethod',
    (useDifferentRole && s3RoleMethodValues[1].value) ||
      s3RoleMethodValues[0].value,
  );

  const glueRole = watch(
    'glue.iamRole',
    (dataSource && dataSource.connection.hive['metastore.glue']['iam-role']) ||
      '',
  );

  const lakeFormationSelected = watch('glue.security', isLakeFormation);

  const region = watch(
    'glue.region',
    (dataSource && dataSource.connection.hive['metastore.glue'].region) ||
      computePlaneRegion,
  );

  const lakeFormationCFUrl = useMemo(() => {
    if (!awsLakeFormationCloudFormationQuickCreateUrl || !region) return null;

    return awsLakeFormationCloudFormationQuickCreateUrl.replace(
      '{regionPlaceholder}',
      region,
    );
  }, [region, awsLakeFormationCloudFormationQuickCreateUrl]);

  const awsGlueS3FormationCFUrl = useMemo(() => {
    if (!awsGlueS3CloudFormationQuickCreateUrl || !region) return null;

    return awsGlueS3CloudFormationQuickCreateUrl.replace(
      '{regionPlaceholder}',
      region,
    );
  }, [region, awsGlueS3CloudFormationQuickCreateUrl]);

  useEffect(() => {
    clearErrors('glue.s3.iamRole');
  }, [roleCreationMethod, s3RoleMethod, clearErrors]);

  useEffect(() => {
    clearErrors('glue');
    clearErrors('prestoUserMappings');
  }, [lakeFormationSelected, clearErrors]);

  const showAuthorization = useMemo(() => {
    return authorizationServices && authorizationServices.length > 0;
  }, [authorizationServices]);

  return (
    <>
      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.awsLakeFormationHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.CheckboxInput
          name='glue.security'
          label={display.awsLakeFormationLabel}
          disabled={
            disabled ||
            unsupportedLakeFormationPrestoClusters ||
            isCommunityEditionTenant
          }
          defaultChecked={isLakeFormation}
          fieldHelp={Help.AWSLakeFormation(isCommunityEditionTenant)}
        />
      </div>

      <div style={{ display: active ? undefined : 'none' }}>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div style={{ display: active ? undefined : 'none' }}>
        <Form.SelectInput
          name='glue.region'
          label={display.regionLabel}
          disabled={disabled}
          defaultValue={
            (dataSource &&
              dataSource.connection.hive['metastore.glue'].region) ||
            computePlaneRegion
          }
          validationRules={{
            validate: required(getValues, type, display.regionRequiredError),
          }}
          items={awsRegions}
          fieldHelp={Help.AWSRegion}
        />
        <div
          style={{
            display: active && lakeFormationSelected ? 'none' : undefined,
          }}
        >
          <Form.TextInput
            name='glue.catalogid'
            label={display.glueCatalogIdLabel}
            disabled={disabled}
            defaultValue={
              dataSource &&
              dataSource.connection.hive['metastore.glue'].catalogid
            }
            validationRules={{
              maxLength: { value: 12, message: display.glueCatalogIdMaxChar },
              pattern: {
                value: awsAccountIdRegex,
                message: display.glueCatalogIdIllegalCharacters,
              },
            }}
            fieldHelp={Help.GlueCatalogId}
            optional
          />
        </div>
      </div>
      <div
        style={{
          display: active && !lakeFormationSelected ? undefined : 'none',
        }}
      >
        <h3>{display.rolesSectionTitle}</h3>
        <div>{display.rolesSectionExplanation}</div>
      </div>
      <div
        style={{
          display: active && !lakeFormationSelected ? undefined : 'none',
        }}
      >
        <Form.RadioInputs
          name='glue.roleCreationMethod'
          label={display.rolesCreationMethodLabel}
          values={roleCreationMethodValues}
          defaultValue={
            (useDifferentRole && roleCreationMethodValues[1].value) ||
            roleCreationMethodValues[0].value
          }
        />

        {roleCreationMethod === roleCreationMethodValues[0].value && (
          <>
            <Data.Label
              label={display.cloudFormationLabel}
              fieldHelp={Help.CloudFormationQuickCreate}
            />
            <Data.ButtonGroup>
              {awsGlueS3FormationCFUrl ? (
                <Page.TertiaryLink
                  to={awsGlueS3FormationCFUrl}
                  disabled={!awsGlueS3FormationCFUrl}
                  external
                  newTab
                >
                  {display.cloudFormationButtonLabel}
                </Page.TertiaryLink>
              ) : (
                <div>Loading...</div>
              )}
            </Data.ButtonGroup>
            <CloudFormationHelpDiv>
              {display.cloudFormationFinalHelp}
            </CloudFormationHelpDiv>
          </>
        )}
        <Form.TextInput
          name='glue.iamRole'
          label={
            roleCreationMethod === roleCreationMethodValues[0].value
              ? display.glueS3RoleLabel
              : display.glueRoleLabel
          }
          disabled={disabled}
          defaultValue={
            dataSource &&
            dataSource.connection.hive['metastore.glue']['iam-role']
          }
          validationRules={{
            validate: ifNotLakeFormation(
              getValues,
              required(
                getValues,
                type,
                display.glueRoleRequiredError,
                patternIAMRole(display.roleARNPatternError),
              ),
            ),
          }}
          fieldHelp={Help.GlueS3RoleArn}
          trimPasted
        />

        <Form.RadioInputs
          name='glue.s3RoleMethod'
          label={display.roleLabel}
          values={s3RoleMethodValues}
          defaultValue={
            (useDifferentRole && s3RoleMethodValues[1].value) ||
            s3RoleMethodValues[0].value
          }
          hidden={roleCreationMethod !== roleCreationMethodValues[1].value}
          fieldHelp={Help.S3RoleARN}
        />
        {roleCreationMethod === roleCreationMethodValues[1].value &&
          s3RoleMethod === s3RoleMethodValues[0].value && (
            <Data.DataField value={glueRole} />
          )}
        <Form.TextInput
          name='glue.s3.iamRole'
          label=''
          disabled={disabled}
          validationRules={{
            validate: ifNotLakeFormation(
              getValues,
              requiredMultipleSelect(
                getValues,
                type,
                [
                  {
                    propName: 'glue.roleCreationMethod',
                    group: roleCreationMethodValues[1].value,
                  },
                  {
                    propName: 'glue.s3RoleMethod',
                    group: s3RoleMethodValues[1].value,
                  },
                ],
                display.roleRequiredError,
                patternIAMRole(display.roleARNPatternError),
              ),
            ),
          }}
          defaultValue={
            dataSource && dataSource.connection.hive?.s3?.['iam-role']
          }
          hidden={
            roleCreationMethod !== roleCreationMethodValues[1].value ||
            s3RoleMethod !== s3RoleMethodValues[1].value
          }
          trimPasted
        />
      </div>

      {showAuthorization && (
        <>
          <div
            style={{
              display: active && !lakeFormationSelected ? undefined : 'none',
            }}
          >
            <h3>{display.authorizationHeader}</h3>
          </div>
          <div
            style={{
              display: active && !lakeFormationSelected ? undefined : 'none',
            }}
          >
            <Form.SelectInput
              name='authorizationServiceId'
              optional
              label={display.authorizationLabel}
              disabled={
                disabled ||
                unsupportedRangerHivePrestoClusters ||
                isCommunityEditionTenant
              }
              items={authorizationServices}
              fieldHelp={Help.AuthorizationService(isCommunityEditionTenant)}
              defaultValue={
                dataSource ? dataSource.authorizationServiceId : undefined
              }
            />
          </div>
        </>
      )}
      <div
        style={{
          display: active && lakeFormationSelected ? undefined : 'none',
        }}
      >
        <h3>{display.lakeFormationSectionTitle}</h3>
        <div>{display.lakeFormationSectionExplanation}</div>
      </div>
      <div
        style={{
          display: active && lakeFormationSelected ? undefined : 'none',
        }}
      >
        <Data.Label
          label={display.lakeFormationSetupLabel}
          optional
          fieldHelp={Help.LakeFormationCloudFormationQuickCreate}
        />
        <Data.ButtonGroup>
          {lakeFormationCFUrl ? (
            <Page.TertiaryLink
              to={lakeFormationCFUrl}
              disabled={!lakeFormationCFUrl}
              external
              newTab
            >
              {display.lakeFormationCFButtonLabel}
            </Page.TertiaryLink>
          ) : (
            <div>Loading...</div>
          )}
        </Data.ButtonGroup>
      </div>
    </>
  );
};

GlueConnectionDetails.defaultProps = {
  disabled: false,
  awsGlueS3CloudFormationQuickCreateUrl: null,
  awsLakeFormationCloudFormationQuickCreateUrl: null,
  dataSource: null,
  unsupportedLakeFormationPrestoClusters: false,
  unsupportedRangerHivePrestoClusters: false,
  authorizationServices: null,
};

GlueConnectionDetails.propTypes = {
  disabled: bool,
  active: bool.isRequired,
  awsRegions: arrayOf(
    shape({
      display: string.isRequired,
      value: string,
    }),
  ).isRequired,
  computePlaneRegion: string.isRequired,
  awsGlueS3CloudFormationQuickCreateUrl: string,
  awsLakeFormationCloudFormationQuickCreateUrl: string,
  dataSource: DataSources.propTypes.DataSource,
  unsupportedLakeFormationPrestoClusters: bool,
  unsupportedRangerHivePrestoClusters: bool,
  authorizationServices: arrayOf(
    shape({
      display: string.isRequired,
      value: string.isRequired,
    }),
  ),
};

export default GlueConnectionDetails;
