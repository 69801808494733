import { status as cpStatus } from '../ComputePlane/constants';

export const status = cpStatus;

export const roleCreationMethod = Object.freeze({
  cloudFormation: 'cloudformation',
  manual: 'manual',
  // The below two types are not available but will be future work
  terraform: 'terraform',
  email: 'emailAdmin',
});

export const vpcTypes = {
  fullyManaged: 'fullyManaged',
  userManaged: 'userManaged',
};

export const stepIds = {
  step1: 'step_1',
  step2: 'step_2',
  step3: 'step_3',
  step4: 'step_4',
  step5: 'step_5',
  step6: 'step_6',
  step7: 'step_7',
  step8: 'step_8',
};

export const verifyRoleCreationMethodType = {
  cloudFormation: 'CloudFormation',
  terraform: 'Terraform',
};
