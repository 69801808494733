import { arrayOf, string, instanceOf } from 'prop-types';
import noop from '../../../utils/noop';
import { Duration } from '../../Date';
import Table from '../../Table';
import Page from '../../Page';
import { ErrorBadge } from '../../Badge';
import { getManagePrestoClusterRoute } from '../routes';
import * as constants from '../constants';
import * as propTypes from '../propTypes';
import { StatusDotDiv } from './styledComponents';

const display = {
  manageButtonLabel: 'Manage',
  statusColumnLabel: 'Status',
  nameColumnLabel: 'Name',
  coordinatorColumnLabel: 'Coordinator',
  workerColumnLabel: 'Workers',
  uptimeColumnLabel: 'Uptime',
  prestoConsole: 'Presto Console',
};

const StatusCell = ({ data, key }) => {
  return (
    <Table.DataCell key={key}>
      {(constants.status.init_failure === data ||
        constants.status.destroy_failure === data ||
        constants.status.restart_failure === data ||
        constants.status.start_failure === data ||
        constants.status.stop_failure === data ||
        constants.status.inactive_failure === data) && <ErrorBadge />}
      {constants.status.init_failure !== data &&
        constants.status.destroy_failure !== data &&
        constants.status.restart_failure !== data &&
        constants.status.start_failure !== data &&
        constants.status.stop_failure !== data &&
        constants.status.inactive_failure !== data && (
          <StatusDotDiv className={data} label={data} />
        )}
    </Table.DataCell>
  );
};

StatusCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const DurationCell = ({ data, key }) => {
  return (
    <Table.DataCell key={key}>
      {data && <Duration startDate={data} />}
    </Table.DataCell>
  );
};

DurationCell.defaultProps = {
  data: null,
};

DurationCell.propTypes = {
  data: instanceOf(Date),
  key: string.isRequired,
};

const ManageCell = ({ data, key }) => {
  return (
    <Table.ButtonCell key={key}>
      {data.prestoClusterUrl && (
        <Page.TertiaryLink to={data.prestoClusterUrl} external newTab>
          {display.prestoConsole}
        </Page.TertiaryLink>
      )}
      <Page.PrimaryLink
        to={data.manageRoute}
        disabled={
          data.status === constants.status.initializing ||
          data.status === constants.status.destroying
        }
      >
        {display.manageButtonLabel}
      </Page.PrimaryLink>
    </Table.ButtonCell>
  );
};

ManageCell.propTypes = {
  data: string.isRequired,
  key: string.isRequired,
};

const columns = [
  {
    name: display.statusColumnLabel,
    selector: (prestoCluster) => {
      return prestoCluster.status;
    },
    cellRender: StatusCell,
    cellWidth: '1fr',
  },
  {
    name: display.nameColumnLabel,
    selector: (prestoCluster) => {
      return prestoCluster.name;
    },
    cellWidth: '2fr',
  },
  {
    name: display.coordinatorColumnLabel,
    selector: (prestoCluster) => {
      return prestoCluster.clusterConfiguration.masterNodes;
    },
    cellWidth: '2fr',
  },
  {
    name: display.workerColumnLabel,
    selector: (prestoCluster) => {
      return prestoCluster.clusterConfiguration.workerNodes;
    },
    cellWidth: '2fr',
  },
  {
    name: display.uptimeColumnLabel,
    selector: (prestoCluster) => {
      if (
        // eslint-disable-next-line react/destructuring-assignment
        prestoCluster.status === constants.status.active ||
        // eslint-disable-next-line react/destructuring-assignment
        prestoCluster.status === constants.status.modifying
      ) {
        return prestoCluster.statusDate;
      }
      return null;
    },
    cellRender: DurationCell,
    cellWidth: '2fr',
  },
  {
    name: 'Manage',
    selector: (prestoCluster) => {
      return {
        manageRoute: getManagePrestoClusterRoute(prestoCluster.prestoClusterId),
        status: prestoCluster.status,
        prestoClusterUrl:
          prestoCluster.status === constants.status.active ||
          prestoCluster.status === constants.status.modifying
            ? prestoCluster.prestoConfiguration.prestoClusterUrl
            : undefined,
      };
    },
    headerRender: noop,
    cellRender: ManageCell,
    cellWidth: '4fr',
  },
];

const dataKeyGenerator = (prestoCluster) => {
  return prestoCluster.prestoClusterId;
};

const PrestoClustersTable = ({ prestoClusters }) => {
  return (
    <Table.Table
      data={prestoClusters}
      columns={columns}
      dataKeyGenerator={dataKeyGenerator}
    />
  );
};

PrestoClustersTable.propTypes = {
  prestoClusters: arrayOf(propTypes.PrestoCluster).isRequired,
};

export default PrestoClustersTable;
