import { useMemo } from 'react';
import { arrayOf, string, bool, shape } from 'prop-types';
import Icons from '../../../icons';
import Help from '../../Help';
import Table from '../../Table';
import Page from '../../Page';
import Form from '../../Form';
import DataSources from '../../DataSources';
import FormHelp from './DataSourcesSelectorHelp';
import { NoDataDiv } from './styledComponents';
import useRouteBuilder from '../../../router/useRouteBuilder';

const display = {
  title: 'Available Data Sources and Catalogs',
  nameColumnLabel: 'Name',
  descriptionColumnLabel: 'Description',
  typeColumnLabel: 'Type',
  selectedColumnLabel: 'Selected',
  dataSourceTypeLabels: {
    hive: 'Hive / S3',
    glue: 'Glue / S3',
    elasticsearch: 'Elasticsearch',
    mysql: 'MySQL',
    postgresql: 'PostgreSQL',
    redshift: 'Redshift',
    db2: 'IBM Db2',
  },
  unknownDataSourceType: 'Unknown',
  noDataSources: 'No data sources found.',
  linkToAddDataSources: 'Add some here',
};

const SelectCell = ({ data, className, key }) => {
  return (
    <Table.DataCell key={key} className={`justify-center ${className}`}>
      {!data.invalidLakeFormation &&
        !data.invalidRangerHive &&
        !data.invalidForPrestissimo &&
        !data.invalidForPrestissimoGlueWithLakeFormation &&
        !data.updatingSecret &&
        !data.deletingSecret &&
        !data.updateOrDeleteSecretFailed && (
          <Form.CheckboxInput
            name={data.dataSourceId}
            defaultChecked={data.defaultValue}
            disabled={data.disabled}
          />
        )}
      {data.invalidLakeFormation && (
        <Help.Button
          fieldHelp={FormHelp.GlueLakeFormation}
          icon={Icons.Alert}
          className='error'
        />
      )}
      {data.invalidRangerHive && (
        <Help.Button
          fieldHelp={FormHelp.RangerHive}
          icon={Icons.Alert}
          className='error'
        />
      )}
      {data.invalidForPrestissimo && (
        <Help.Button
          fieldHelp={FormHelp.Prestissimo}
          icon={Icons.Alert}
          className='error'
        />
      )}
      {!data.invalidLakeFormation &&
        data.invalidForPrestissimoGlueWithLakeFormation && (
          <Help.Button
            fieldHelp={FormHelp.PrestissimoGlueWithLakeFormation}
            icon={Icons.Alert}
            className='error'
          />
        )}
      {data.updatingSecret && (
        <Help.Button
          fieldHelp={FormHelp.UpdatingSecret}
          icon={Icons.Progress}
          className='warning'
        />
      )}
      {data.deletingSecret && (
        <Help.Button
          fieldHelp={FormHelp.DeletingSecret}
          icon={Icons.Progress}
          className='warning'
        />
      )}
      {data.updateOrDeleteSecretFailed && (
        <Help.Button
          fieldHelp={FormHelp.UpdateOrDeleteSecretFailed(
            data.status,
            data.errorMessages,
          )}
          icon={Icons.Alert}
          className='error'
        />
      )}
    </Table.DataCell>
  );
};

SelectCell.defaultProps = {
  className: '',
};

SelectCell.propTypes = {
  data: shape({
    dataSourceId: string.isRequired,
    defaultValue: bool.isRequired,
    isCommunityEditionCluster: bool.isRequired,
    errorMessages: arrayOf(
      shape({
        errorSummary: string,
        errorMessage: string,
      }),
    ),
  }).isRequired,
  className: string,
  key: string.isRequired,
};

const columns = [
  {
    name: display.nameColumnLabel,
    selector: (dataSource) => {
      return dataSource.name;
    },
    cellWidth: '2fr',
  },
  {
    name: display.descriptionColumnLabel,
    selector: (dataSource) => {
      return dataSource.description;
    },
    cellWidth: 'auto',
  },
  {
    name: display.typeColumnLabel,
    selector: (dataSource) => {
      const label = display.dataSourceTypeLabels[dataSource.type];
      if (!label) {
        return display.unknownDataSourceType;
      }
      return label;
    },
    cellWidth: '2fr',
  },
  {
    name: display.selectedColumnLabel,
    selector: (dataSource, additionalContext) => {
      const defaultValue =
        additionalContext.prestoClusterDataSourceIds.includes(
          dataSource.dataSourceId,
        );

      return {
        defaultValue,
        dataSourceId: dataSource.dataSourceId,
        status: dataSource.status,
        updatingSecret:
          !defaultValue &&
          dataSource.status === DataSources.constants.status.secret_updating,
        deletingSecret:
          dataSource.status === DataSources.constants.status.secret_deleting,
        updateOrDeleteSecretFailed:
          !defaultValue &&
          (dataSource.status === DataSources.constants.status.secret_failure ||
            dataSource.status === DataSources.constants.status.delete_failure),
        invalidLakeFormation: dataSource.invalidLakeFormation,
        invalidRangerHive: dataSource.invalidRangerHive,
        invalidForPrestissimo: dataSource.invalidForPrestissimo,
        invalidForPrestissimoGlueWithLakeFormation:
          dataSource.invalidForPrestissimoGlueWithLakeFormation,
        disabled: additionalContext.disabled,
        isCommunityEditionCluster: additionalContext.isCommunityEditionCluster,
        errorMessages: dataSource.errorMessages,
      };
    },
    cellRender: SelectCell,
    cellWidth: '1fr',
  },
];

const dataKeyGenerator = (dataSource) => {
  return dataSource.dataSourceId;
};

const DataSourcesSelector = ({
  dataSources,
  prestoClusterDataSourceIds,
  disabled,
  isCommunityEditionCluster,
}) => {
  const addDataSourceRoute = useRouteBuilder(
    DataSources.routes.getAddDataSourceRoute,
  );
  const additionalContext = useMemo(() => {
    return {
      prestoClusterDataSourceIds,
      disabled,
      isCommunityEditionCluster,
    };
  }, [prestoClusterDataSourceIds, disabled, isCommunityEditionCluster]);
  return (
    <Page.Box>
      <Page.TableBoxHeader>
        <h2>{display.title}</h2>
      </Page.TableBoxHeader>
      {dataSources && dataSources.length === 0 && (
        <NoDataDiv>
          {display.noDataSources}{' '}
          <Page.SecondaryLink to={addDataSourceRoute}>
            {display.linkToAddDataSources}
          </Page.SecondaryLink>
        </NoDataDiv>
      )}
      {dataSources && dataSources.length > 0 && (
        <Table.Table
          data={dataSources}
          columns={columns}
          dataKeyGenerator={dataKeyGenerator}
          additionalContext={additionalContext}
        />
      )}
    </Page.Box>
  );
};

DataSourcesSelector.defaultProps = {
  disabled: false,
};

DataSourcesSelector.propTypes = {
  dataSources: arrayOf(DataSources.propTypes.DataSource).isRequired,
  prestoClusterDataSourceIds: arrayOf(string).isRequired,
  disabled: bool,
  isCommunityEditionCluster: bool.isRequired,
};

export default DataSourcesSelector;
