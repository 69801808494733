import { bool, string } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import Form from '../../Form';
import { clusterNameRegex } from '../../../utils/regex';
import Help from './PrestoClusterDetailsHelp';
import {
  ClusterSettingsConcurrencyDiv,
  ClusterSettingsConcurrencyDivs,
} from './styledComponents';

const display = {
  boxTitle: 'General',
  formContentHeader: 'Cluster Name',
  formExplaination: 'Secondary explanation',
  nameLabel: 'Name',
  nameRequiredError: 'Name is required',
  nameTooLong:
    'The cluster name must begin and end with a letter or number. It can be a maximum of 50 characters long.',
  nameFormatError:
    'This value can only contain letters, numbers, hyphens and spaces. It must begin and end with a letter or number.',
  concurrencyLabel: 'Workload Profile',
  concurrencyRequiredError: 'Concurrency mode is required',
  concurrencyLowValue: 'low',
  concurrencyLowLabel: 'Low Concurrency',
  concurrencyLowDetails:
    'Low concurrency is useful for clusters that run a limited number of queries or a few large, complex queries. It also supports bigger and heavier ETL jobs.',
  concurrencyHighValue: 'high',
  concurrencyHighLabel: 'High Concurrency',
  concurrencyHighDetails:
    'High concurrency is better for running multiple queries at the same time. For example, dashboard and reporting queries or A/B testing analytics, etc.',
};

const concurrencyMode = (label, details) => {
  return () => {
    return (
      <ClusterSettingsConcurrencyDiv>
        <div>{label}</div>
        <div className='details'>{details}</div>
      </ClusterSettingsConcurrencyDiv>
    );
  };
};

const concurrencyValues = [
  {
    value: display.concurrencyLowValue,
    label: display.concurrencyLowLabel,
    component: concurrencyMode(
      display.concurrencyLowLabel,
      display.concurrencyLowDetails,
    ),
  },
  {
    value: display.concurrencyHighValue,
    label: display.concurrencyHighLabel,
    component: concurrencyMode(
      display.concurrencyHighLabel,
      display.concurrencyHighDetails,
    ),
  },
];

const PrestoClusterDetails = ({ error, disabled }) => {
  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        <div>
          <h3>{display.formContentHeader}</h3>
          {/* <div>{display.formExplaination}</div> */}
        </div>
        <div>
          <Form.TextInput
            name='name'
            label={display.nameLabel}
            disabled={disabled}
            validationRules={{
              required: display.nameRequiredError,
              maxLength: { value: 50, message: display.nameTooLong },
              pattern: {
                value: clusterNameRegex,
                message: display.nameFormatError,
              },
            }}
            fieldHelp={Help.Name}
          />
        </div>
        <ClusterSettingsConcurrencyDivs>
          <Form.RadioInputs
            name='concurrency'
            label={display.concurrencyLabel}
            values={concurrencyValues}
            fieldHelp={Help.ConcurrencyMode}
            defaultValue={display.concurrencyLowValue}
          />
        </ClusterSettingsConcurrencyDivs>
      </Page.FormContent>
    </Page.Box>
  );
};

PrestoClusterDetails.defaultProps = {
  disabled: false,
  error: undefined,
};

PrestoClusterDetails.propTypes = {
  disabled: bool,
  error: string,
};

export default PrestoClusterDetails;
