import { createSelector } from 'reselect';
import { isCommunityEditionTenant } from '../AccountSetup/selectors';
import ComputePlane from '../ComputePlane';
import * as constants from './constants';

export const getErrors = (state) => {
  return state.prestoClusterAdd.errors;
};

export const getSubmitInProgress = (state) => {
  return state.prestoClusterAdd.submitInProgress;
};

export const getSubmitSuccess = (state) => {
  return state.prestoClusterAdd.submitSuccess;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getEnableSpot = (state) => {
  const isCommunityEdition = isCommunityEditionTenant(state);
  // return (
  //   !isCommunityEdition &&
  //   state.accountSetup.tenant &&
  //   state.accountSetup.tenant.featureFlags &&
  //   state.accountSetup.tenant.featureFlags.spotInstance
  // );
  // Enable spot for all tenants for right now. I'm going to leave this in place
  // for right now since Spot functionality is still not fully baked.
  return !isCommunityEdition;
};

export const getMaxWorkerNodeCount = (state) => {
  const maxWorkerNodeCount =
    state.accountSetup.tenant &&
    state.accountSetup.tenant.featureFlags &&
    state.accountSetup.tenant.featureFlags.maxWorkerNodeCount;
  return maxWorkerNodeCount || constants.maxWorkerNodes;
};

export const getPrestissimoEnabled = (state) => {
  const prestissimoEnabled =
    state.accountSetup.tenant?.featureFlags?.prestissimo;
  return prestissimoEnabled || false;
};

export const getInstanceTypeValues = createSelector(
  isCommunityEditionTenant,
  (communityEditionTenant) => {
    if (communityEditionTenant) {
      return constants.communityEditionInstanceTypes;
    }
    return constants.instanceTypeValues;
  },
);

export const getCoordinatorInstanceTypeValues = createSelector(
  isCommunityEditionTenant,
  (communityEditionTenant) => {
    if (communityEditionTenant) {
      return constants.communityEditionCoordinatorInstanceTypes;
    }
    return constants.coordinatorInstanceTypeValues;
  },
);

export const getAZSelect = createSelector(
  [ComputePlane.selectors.getActiveComputePlane],
  (computePlane) => {
    return computePlane?.awsAvailabilityZones?.length > 0
      ? computePlane.awsAvailabilityZones
      : null;
  },
);

export const getHmsInstanceTypeValues = createSelector(
  isCommunityEditionTenant,
  (communityEditionTenant) => {
    if (communityEditionTenant) {
      return constants.communityEditionInstanceTypes;
    }
    return constants.hmsInstanceTypeValues;
  },
);
