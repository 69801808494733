import { OptionalBadge } from '../../Badge';

const display = {
  prestissimo: 'prestissimo',
};

const PrestissimoBadge = () => {
  return <OptionalBadge>{display.prestissimo}</OptionalBadge>;
};

export default PrestissimoBadge;
