import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { bool, string, shape, number, arrayOf } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import PrestoUsers from '../../PrestoUsers';
import { useEnableFeature } from '../../utils/featureHooks';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import PrestoUsersSelector from './PrestoUsersSelector';
import PrestoClusterInfo from './PrestoClusterInfo';
import PendingStatus from './PendingStatus';
import PageHelp from './PageHelp';

const display = {
  header: 'Presto Users',
  cancelButton: 'Cancel',
  saveButton: 'Save',
  savingButton: 'Saving...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  confirmTitle:
    'This action will update the credentials of your Presto cluster.',
  confirmDetails:
    'Please wait a couple of minutes for the credentials change to take effect.',
};

const ChangePrestoUsersForm = ({
  prestoUsers,
  prestoUserIds,
  name,
  status,
  actionStatus,
  prestoClusterId,
  submitSuccess,
  usePop,
}) => {
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  const enableFeature = useEnableFeature();

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      setConfirmSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  const onConfirm = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const cancelConfirm = useCallback(() => {
    setConfirmSubmit(false);
  }, []);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const canChangePrestoUsers =
    enableFeature &&
    status !== PrestoClusters.constants.status.initializing &&
    status !== PrestoClusters.constants.status.modifying &&
    status !== PrestoClusters.constants.status.restarting &&
    status !== PrestoClusters.constants.status.starting &&
    status !== PrestoClusters.constants.status.stopping &&
    status !== PrestoClusters.constants.status.destroying;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editPrestoUsers';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {!canChangePrestoUsers && (
            <Banner scrollIntoView>
              <PendingStatus name={name} status={status} />
            </Banner>
          )}
          {showActionStatusError && (
            <Banner scrollIntoView title={actionStatus.error} />
          )}
          <PrestoClusterInfo name={name} />
          <PrestoUsersSelector
            prestoUsers={prestoUsers}
            prestoUserIds={prestoUserIds}
            disabled={submitInProgress}
          />
          {!submitInProgress && confirmSubmit && (
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={cancelConfirm}
                  label={display.cancelButton}
                />
                <Form.Submit label={display.saveButton} />
              </div>
            </Banner>
          )}
          {(submitInProgress || !confirmSubmit) && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              <Form.PrimaryButton
                onClick={onConfirm}
                label={
                  !submitInProgress ? display.saveButton : display.savingButton
                }
                disabled={submitInProgress || !canChangePrestoUsers}
                showLoading={submitInProgress}
              />
            </Page.Buttons>
          )}
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

ChangePrestoUsersForm.defaultProps = {
  actionStatus: undefined,
  prestoUsers: null,
  prestoUserIds: null,
  name: undefined,
  status: null,
  submitSuccess: false,
  usePop: false,
};

ChangePrestoUsersForm.propTypes = {
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  prestoUserIds: arrayOf(string),
  name: string,
  status: string,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  prestoClusterId: string.isRequired,
  submitSuccess: bool,
  usePop: bool,
};

export default ChangePrestoUsersForm;
