import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { getRegionFullName } from '../../utils/regionUtils';
import { getAwsRegions } from './selectors';

const AwsRegion = ({ regionValue }) => {
  const awsRegions = useSelector((state) => {
    return getAwsRegions(state);
  });

  const regionFullName = useMemo(() => {
    return getRegionFullName(regionValue, awsRegions);
  }, [regionValue, awsRegions]);

  return regionFullName || null;
};

AwsRegion.defaultProps = {
  regionValue: undefined,
};

AwsRegion.propTypes = {
  regionValue: string,
};

export default AwsRegion;
