export const status = Object.freeze({
  initializing: 'initializing',
  active: 'active',
  init_failure: 'init_failure',
  modifying: 'modifying',
  restarting: 'restarting',
  restart_failure: 'restart_failure',
  starting: 'starting',
  start_failure: 'start_failure',
  stopping: 'stopping',
  stop_failure: 'stop_failure',
  inactive: 'inactive',
  inactive_failure: 'inactive_failure',
  destroying: 'destroying',
  destroyed: 'destroyed',
  destroy_failure: 'destroy_failure',
  archived: 'archived',
  archiving: 'archiving',
});

export const simpleStatus = Object.freeze({
  running: 'running',
  stopped: 'stopped',
  deleted: 'deleted',
  error: 'error',
  pending: 'pending',
});

export const getSimpleStatus = (fullStatus) => {
  switch (fullStatus) {
    case status.active:
      return simpleStatus.running;
    case status.destroyed:
    case status.archived:
      return simpleStatus.deleted;
    case status.inactive:
      return simpleStatus.stopped;
    case status.init_failure:
    case status.destroy_failure:
    case status.restart_failure:
    case status.start_failure:
    case status.stop_failure:
    case status.inactive_failure:
      return simpleStatus.error;
    case status.initializing:
    case status.destroying:
    case status.restarting:
    case status.starting:
    case status.stopping:
    case status.modifying:
    case status.archiving:
      return simpleStatus.pending;
    default:
      return fullStatus;
  }
};
