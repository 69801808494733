import { useEffect, useState } from 'react';
import { instanceOf, bool } from 'prop-types';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import Timespan from './Timespan';

const getDifference = (startDate, endDate) => {
  const difference = differenceInMilliseconds(endDate, startDate);

  return difference;
};

const Duration = ({ startDate, endDate, skipRefresh }) => {
  const [difference, setDifference] = useState(
    getDifference(startDate, endDate ?? new Date()),
  );

  useEffect(() => {
    if (skipRefresh) {
      setDifference(getDifference(startDate, endDate ?? new Date()));
      return () => {};
    }

    const timer = setInterval(() => {
      setDifference(getDifference(startDate, endDate ?? new Date()));
    }, 1000 * 60);
    return () => {
      clearInterval(timer);
    };
  }, [startDate, endDate, skipRefresh]);

  return <Timespan milliseconds={difference} />;
};

Duration.defaultProps = {
  endDate: null,
  skipRefresh: false,
};

Duration.propTypes = {
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date),
  skipRefresh: bool,
};

export default Duration;
