import { string } from 'prop-types';
import { ErrorBadge, SuccessBadge, WarningBadge } from '../../Badge';
import PrestoClusters from '../../PrestoClusters';

const display = {
  pending: 'Pending',
  running: 'Running',
  stopped: 'Stopped',
  error: 'Error',
};

const Active = () => {
  return <SuccessBadge>{display.running}</SuccessBadge>;
};

const Error = () => {
  return <ErrorBadge>{display.error}</ErrorBadge>;
};

const Inactive = () => {
  return <ErrorBadge>{display.stopped}</ErrorBadge>;
};

const Pending = () => {
  return <WarningBadge>{display.pending}</WarningBadge>;
};

const TitleNav = ({ name, status }) => {
  let StatusBadge;
  switch (status) {
    case PrestoClusters.constants.status.active:
      StatusBadge = Active;
      break;
    case PrestoClusters.constants.status.destroyed:
    case PrestoClusters.constants.status.inactive:
    case PrestoClusters.constants.status.archived:
      StatusBadge = Inactive;
      break;
    case PrestoClusters.constants.status.init_failure:
    case PrestoClusters.constants.status.destroy_failure:
    case PrestoClusters.constants.status.restart_failure:
    case PrestoClusters.constants.status.start_failure:
    case PrestoClusters.constants.status.stop_failure:
    case PrestoClusters.constants.status.inactive_failure:
      StatusBadge = Error;
      break;
    case PrestoClusters.constants.status.initializing:
    case PrestoClusters.constants.status.destroying:
    case PrestoClusters.constants.status.restarting:
    case PrestoClusters.constants.status.starting:
    case PrestoClusters.constants.status.stopping:
    case PrestoClusters.constants.status.modifying:
    case PrestoClusters.constants.status.archiving:
      StatusBadge = Pending;
      break;
    default:
      StatusBadge = null;
      break;
  }
  return (
    <h1>
      {name}
      <StatusBadge />
    </h1>
  );
};

TitleNav.propTypes = {
  name: string.isRequired,
  status: string.isRequired,
};

export default TitleNav;
