import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { arrayOf, bool, string } from 'prop-types';
import Page from '../../Page';
import Form from '../../Form';
import Data from '../../Data';
import Banner from '../../Banner';
import { dInstanceTypeRegex } from '../../../utils/regex';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import { DropDownValue } from '../propTypes';
import Help from './DataLakeSettingsHelp';

const display = {
  boxTitle: 'Data Lake Settings',
  dataLakeCachingHeader: 'Data Lake Caching',
  enableDataIOCachingLabel: 'Enable Data IO Cache',
  enableFragmentResultCache: 'Enable Intermediate Result Set Cache',
  hiveMetastoreHeader: 'Hive Metastore',
  ahanaManagedHMSLabel: 'Attach an IBM watsonx.data Hive Metastore',
  hmsInstanceTypeLabel: 'Hive Metastore Instance Type',
  hmsInstanceTypeRequiredError: 'Hive Metastore AWS instance type is required',
  attachQueryLogTableLabel: 'Enable cluster query log',
  prestissimoHeader: 'Prestissimo',
  prestissimoLabel: 'Provision Prestissimo Worker Nodes',
};

const DataLakeSettings = ({
  hmsInstanceTypeValues,
  error,
  disabled,
  prestissimoEnabled,
}) => {
  const { watch, setValue } = useFormContext();
  const createHms = watch('ahanaManagedHms', true);
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const instanceType = watch('instanceType');
  const dWorkerInstanceTypeSelected = useMemo(() => {
    return dInstanceTypeRegex.test(instanceType);
  }, [instanceType]);

  const prestissimoSelected = watch('prestissimo');

  useEffect(() => {
    setValue('attachQueryLogTable', false);
  }, [setValue, prestissimoSelected]);

  useEffect(() => {
    if (prestissimoSelected) {
      setValue('enableFragmentResultCache', false);
    } else {
      setValue('enableFragmentResultCache', dWorkerInstanceTypeSelected);
    }
  }, [setValue, dWorkerInstanceTypeSelected, prestissimoSelected]);

  useEffect(() => {
    setValue('enableDataCache', dWorkerInstanceTypeSelected);
  }, [setValue, dWorkerInstanceTypeSelected]);

  return (
    <Page.Box>
      <Page.BoxHeader>
        <h2>{display.boxTitle}</h2>
      </Page.BoxHeader>
      {error && <Banner title={error} scrollIntoView />}
      <Page.FormContent>
        {prestissimoEnabled && (
          <>
            <div>
              <h3>{display.prestissimoHeader}</h3>
            </div>
            <div>
              <Form.CheckboxInput
                name='prestissimo'
                label={display.prestissimoLabel}
                fieldHelp={Help.Prestissimo}
              />
            </div>
          </>
        )}
        <div>
          <h3>{display.hiveMetastoreHeader}</h3>
        </div>
        <div>
          <Form.CheckboxInput
            name='ahanaManagedHms'
            label={display.ahanaManagedHMSLabel}
            fieldHelp={Help.AhanaManagedHMS}
            defaultChecked
          />
          {!createHms && (
            <>
              <Data.DataField
                label={display.hmsInstanceTypeLabel}
                disabled
                fieldHelp={Help.HMSInstanceType(isCommunityEditionTenant)}
              />
              <Form.CheckboxInput
                name='attachQueryLogTablePlaceholder'
                label={display.attachQueryLogTableLabel}
                disabled /* always disabled, regardless of prestissimo status, because of no HMS */
                fieldHelp={Help.AttachQueryLogTable}
              />
            </>
          )}
          {createHms && (
            <>
              <Form.SelectInput
                name='hmsInstanceType'
                label={display.hmsInstanceTypeLabel}
                disabled={disabled}
                defaultValue='m5.xlarge'
                items={hmsInstanceTypeValues}
                validationRules={{
                  required: display.hmsInstanceTypeRequiredError,
                }}
                fieldHelp={Help.HMSInstanceType(isCommunityEditionTenant)}
              />
              <Form.CheckboxInput
                name='attachQueryLogTable'
                label={display.attachQueryLogTableLabel}
                disabled={disabled || prestissimoSelected}
                defaultChecked
                fieldHelp={Help.AttachQueryLogTable}
              />
            </>
          )}
        </div>
        <div>
          <h3>{display.dataLakeCachingHeader}</h3>
        </div>
        <div>
          <Form.CheckboxInput
            name='enableDataCache'
            label={display.enableDataIOCachingLabel}
            fieldHelp={Help.DataLakeIOCaching(isCommunityEditionTenant)}
            disabled={dWorkerInstanceTypeSelected || isCommunityEditionTenant}
          />
          <Form.CheckboxInput
            name='enableFragmentResultCache'
            label={display.enableFragmentResultCache}
            fieldHelp={Help.FragmentResultCaching(isCommunityEditionTenant)}
            disabled={
              dWorkerInstanceTypeSelected ||
              isCommunityEditionTenant ||
              prestissimoSelected
            }
          />
        </div>
      </Page.FormContent>
    </Page.Box>
  );
};

DataLakeSettings.defaultProps = {
  disabled: false,
  error: undefined,
};

DataLakeSettings.propTypes = {
  disabled: bool,
  error: string,
  hmsInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  prestissimoEnabled: bool.isRequired,
};

export default DataLakeSettings;
