export const iamRoleRegex =
  /^arn:aws:iam::\d{12}:role\/[A-Za-z0-9+=,.@\-_]{1,128}$/;
export const awsAccessKeyRegex = /^[A-Z0-9]{20}$/;
export const awsSecretRegex = /^[A-Za-z0-9/+=]{40}$/;
export const mysqlJDBCRegex = /^jdbc:mysql:\/\/.+/;
export const postgresJDBCRegex = /^jdbc:postgresql:\/\/.+/;
export const redshiftJDBCRegex = /^jdbc:postgresql:\/\/.+/;
export const clusterNameRegex = /^[A-Za-z0-9][A-Za-z0-9-\s]{0,61}[A-Za-z0-9]$/;
export const subdomainNameRegex = /^[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ipRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const keyNameRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
export const thriftHostnameURIRegex =
  /(^(\s*)thrift:\/\/)(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(:)(([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))(\/?(\s*)$)/;
export const thriftIPAddressURIRegex =
  /(^(\s*)thrift:\/\/)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:)(([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))(\/?(\s*)$)/;
export const redshiftEndpointRegex =
  /(^([a-zA-Z0-9-.]+))(:)(([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))(\/)([a-zA-Z0-9_.]+)(\/?(\s*)$)/;
export const connectionParametersOrConfigurationPropertiesRegex =
  /(?:(?:[a-zA-Z0-9_.%-]+)=(?:[a-zA-Z0-9_.,%-]+))+/;
export const elasticSearchHostnameRegex =
  /^(\s*)https:\/\/(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(\/?(\s*))$/;
export const mysqlEndpointRegex =
  /(^(\s*)(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(\/?(\s*))$)/;
export const rangerHiveHostnameEndpointRegex =
  /(^(\s*)https?:\/\/)(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(:)(([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))(\/?(\s*)$)/;
export const rangerHiveIPAddressEndpointRegex =
  /(^(\s*)https?:\/\/)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:)(([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))(\/?(\s*)$)/;
export const awsAccountIdRegex = /^\d{12}$/;
export const vpcIdRegex = /^vpc-[a-z0-9]+$/;
export const dInstanceTypeRegex = /^(?:[a-z][0-9]d|i3)\.[0-9]*[a-z]+$/;
export const genericURLRegex = /^(http|https):\/\/[^ "]+$/;
export const customTagKeyRegex = /^[A-Za-z0-9 _.:/=+-@]{1,128}$/;
export const customTagValueRegex = /^[A-Za-z0-9 _.:/=+-@]{0,256}$/;
