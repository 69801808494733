import { connect as reduxConnect } from 'react-redux';

export const connect = (
  mapStateToProps,
  dispatchProps,
  mergeProps,
  options,
) => {
  return reduxConnect(mapStateToProps, dispatchProps, mergeProps, options);
};
