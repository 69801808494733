import Help from '../../Help';

const Name = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Name</Help.HelpHeader>
      <Help.P>
        Pick a cluster name. This is unique across the IBM watsonx.data compute
        plane. We recommend a descriptive name to help you identify clusters. It
        will also be used as part of the cluster endpoints. Example:
        https://telemetry.tenant.cp.cvpc.lakehouse.cloud.ibm.com
      </Help.P>
      <Help.P>
        The cluster name must begin and end with a letter or number. It can be a
        maximum of 63 characters long.
      </Help.P>
    </Help.HelpSection>
  );
};

const ConcurrencyMode = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Workload Profile</Help.HelpHeader>
      <Help.P>
        Select a workload type for your cluster that suits the type of queries
        you are planning to run.
      </Help.P>
      <Help.P>
        Low concurrency is useful for clusters that run a limited number of
        queries or a few large, complex queries. It also supports bigger and
        heavier ETL jobs.
      </Help.P>
      <Help.P>
        High concurrency is better for running multiple queries at the same
        time. For example, dashboard and reporting queries or A/B testing
        analytics, etc.
      </Help.P>
      <Help.P>
        This setting can be changed once the cluster has been created and
        cluster restart is not required.{' '}
        <Help.Strong>
          However, the change will only apply to new queries.
        </Help.Strong>
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  Name,
  ConcurrencyMode,
};

export default module;
