/* eslint-disable react/jsx-props-no-spreading */
import { string, oneOfType, number } from 'prop-types';
import { Timespan } from '../../Date';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { InformationGridDiv } from '../../Page/styledComponents';

const display = {
  title: 'Cluster Scaling',
  minimumWorkerNodeCount: 'Minimum worker node count',
  maximumWorkerNodeCount: 'Maximum worker node count',
  cpuScaleInStepSize: 'Scaling Step Size',
  cpuScaleInTimeWindow: 'Scale In Time Window',
  idleTimeWindow: 'Time window before scaling to minimum node count',
  defaultWorkerNodeCount: 'Default worker node count',
  idleTimeWindowSingle: 'Time window before scaling to a single node',
  changeAutoScalingPolicyButton: 'Change Scaling Policy',
};

const IdleCostSavings = ({ workerNodes, idleTime }) => {
  return (
    <InformationGridDiv style={{ '--grid-columns': 2 }}>
      <div className='cell'>
        <div className='title'>{display.defaultWorkerNodeCount}</div>
        <div className='data'>{workerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.idleTimeWindowSingle}</div>
        <div className='data'>
          <Timespan milliseconds={idleTime * 60 * 1000} />
        </div>
      </div>
    </InformationGridDiv>
  );
};

IdleCostSavings.propTypes = {
  workerNodes: number.isRequired,
  idleTime: number.isRequired,
};

const CPUScaleIn = ({ minWorkerNodes, maxWorkerNodes, stepSize, idleTime }) => {
  return (
    <InformationGridDiv style={{ '--grid-columns': 2 }}>
      <div className='cell'>
        <div className='title'>{display.minimumWorkerNodeCount}</div>
        <div className='data'>{minWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.maximumWorkerNodeCount}</div>
        <div className='data'>{maxWorkerNodes}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInStepSize}</div>
        <div className='data'>{stepSize}</div>
      </div>
      <div className='cell'>
        <div className='title'>{display.cpuScaleInTimeWindow}</div>
        <div className='data'>
          <Timespan milliseconds={idleTime * 60 * 1000} />
        </div>
      </div>
    </InformationGridDiv>
  );
};

CPUScaleIn.propTypes = {
  minWorkerNodes: number.isRequired,
  maxWorkerNodes: number.isRequired,
  stepSize: number.isRequired,
  idleTime: number.isRequired,
};

const AutoScaling = ({ prestoClusterId, autoScaling, status }) => {
  const enableEditLink = useEnableFeature();

  if (!autoScaling) {
    return null;
  }

  const canChangeAutoScaling =
    status === PrestoClusters.constants.status.active ||
    status === PrestoClusters.constants.status.inactive;

  return (
    <Page.BoxNavTarget>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoClusters.routes.getPrestoClusterAutoScalingPolicy(
              prestoClusterId,
            )}
            disabled={!canChangeAutoScaling || !enableEditLink}
          >
            {display.changeAutoScalingPolicyButton}
          </Page.PrimaryLink>
        </div>
      </Page.BoxHeader>
      {autoScaling.type === 'cpu' && <CPUScaleIn {...autoScaling} />}
      {autoScaling.type === 'idleCostSavings' && (
        <IdleCostSavings {...autoScaling} />
      )}
    </Page.BoxNavTarget>
  );
};

AutoScaling.defaultProps = {
  autoScaling: null,
};

AutoScaling.propTypes = {
  prestoClusterId: string.isRequired,
  status: string.isRequired,
  autoScaling: oneOfType([
    PrestoClusters.propTypes.AutoScalingPolicyIdleCostSavings,
    PrestoClusters.propTypes.AutoScalingPolicyCPU,
  ]),
};

export default AutoScaling;
