import Help from '../../Help';

const PageHelp = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Trigger Heap Dump Generation and Transfer
      </Help.HelpHeader>
      <Help.OrderedList>
        <Help.ListItem>
          Enter comma-separated Kubernetes node names to trigger heap dump
          generation and transfer to the IBM S3 support bucket.
        </Help.ListItem>
      </Help.OrderedList>
      <Help.P>
        This will run a process on the selected Presto worker or coordinator to
        collect the requested data.
      </Help.P>
    </Help.HelpSection>
  );
};

export default PageHelp;
