import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { bool, arrayOf, string, shape, number } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import DataSources from '../../DataSources';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import PrestoClusterInfo from './PrestoClusterInfo';
import DataSourcesSelector from './DataSourcesSelector';
import PageHelp from './PageHelp';

const display = {
  header: 'Data Sources and Catalogs',
  cancelButton: 'Cancel',
  saveButton: 'Modify Cluster',
  savingButton: 'Saving...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  confirmTitle: 'This action will restart the entire Presto cluster.',
  confirmDetails:
    'This will restart the Presto cluster. All queries running will fail. The cluster will be moved to pending state and will be unusable until it moves back to active state.',
};

const AttachDataSourcesForm = ({
  prestoClusterId,
  prestoCluster,
  actionStatus,
  prestoClusterDataSourceIds,
  dataSources,
  isCommunityEditionCluster,
  usePop,
  submitSuccess,
}) => {
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      setConfirmSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  const onConfirm = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const cancelConfirm = useCallback(() => {
    setConfirmSubmit(false);
  }, []);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const isNotActive =
    prestoCluster.status !== PrestoClusters.constants.status.active;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editDataSources';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <>
            {showActionStatusError && (
              <Banner scrollIntoView title={actionStatus.error} />
            )}
            <PrestoClusterInfo prestoCluster={prestoCluster} />
            <DataSourcesSelector
              dataSources={dataSources}
              prestoClusterDataSourceIds={prestoClusterDataSourceIds}
              disabled={submitInProgress}
              isCommunityEditionCluster={isCommunityEditionCluster}
            />
            {!submitInProgress && confirmSubmit && (
              <Banner
                title={display.confirmTitle}
                details={display.confirmDetails}
              >
                <div className='buttons'>
                  <Form.SecondaryButton
                    onClick={cancelConfirm}
                    label={display.cancelButton}
                    disabledOnErrors={false}
                  />
                  <Form.Submit label={display.saveButton} />
                </div>
              </Banner>
            )}
            {(submitInProgress || !confirmSubmit) && (
              <Page.Buttons>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                  disabled={submitInProgress}
                  disabledOnErrors={false}
                />
                {isNotActive ? (
                  <Form.Submit
                    label={
                      !submitInProgress
                        ? display.saveButton
                        : display.savingButton
                    }
                    disabled={submitInProgress}
                    showLoading={submitInProgress}
                  />
                ) : (
                  <Form.PrimaryButton
                    onClick={onConfirm}
                    label={
                      !submitInProgress
                        ? display.saveButton
                        : display.savingButton
                    }
                    disabled={submitInProgress}
                    showLoading={submitInProgress}
                  />
                )}
              </Page.Buttons>
            )}
          </>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AttachDataSourcesForm.defaultProps = {
  prestoCluster: null,
  prestoClusterDataSourceIds: null,
  dataSources: null,
  actionStatus: undefined,
  usePop: false,
  submitSuccess: false,
};

AttachDataSourcesForm.propTypes = {
  prestoClusterId: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  prestoClusterDataSourceIds: arrayOf(string),
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  isCommunityEditionCluster: bool.isRequired,
  usePop: bool,
  submitSuccess: bool,
};

export default AttachDataSourcesForm;
