import Help from '../../Help';
import DataSources from '../../DataSources';

const GlueLakeFormation = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Incompatible with Lake Formation</Help.HelpHeader>
      <Help.P>
        <Help.Strong>
          This Presto cluster is incompatible with Lake Formation.
        </Help.Strong>
      </Help.P>
      <Help.P>
        You cannot attach a Lake Formation enabled Glue data source to Presto
        clusters that are not compatible with Lake Formation. Community edition
        clusters do not support Lake Formation.
      </Help.P>
    </Help.HelpSection>
  );
};

const RangerHive = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Incompatible with Ranger Hive</Help.HelpHeader>
      <Help.P>
        <Help.Strong>
          This Presto cluster is incompatible with Ranger Hive.
        </Help.Strong>
      </Help.P>
      <Help.P>
        You cannot attach a Ranger enabled Hive data source to Presto clusters
        that are not compatible with Ranger Hive. Community edition clusters do
        not support Lake Formation.
      </Help.P>
    </Help.HelpSection>
  );
};

const Prestissimo = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Incompatible with Prestissimo</Help.HelpHeader>
      <Help.P>
        <Help.Strong>
          This Prestissimo Presto cluster is incompatible with this data source
          type.
        </Help.Strong>
      </Help.P>
      <Help.P>
        You can only attach Hive and Glue data sources to a Prestissimo cluster.
      </Help.P>
    </Help.HelpSection>
  );
};

const PrestissimoGlueWithLakeFormation = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Incompatible with Prestissimo</Help.HelpHeader>
      <Help.P>
        <Help.Strong>
          This Prestissimo Presto cluster is incompatible with this data source
          type.
        </Help.Strong>
      </Help.P>
      <Help.P>
        You cannot attach a Lake Formation enabled Glue data source to a
        Prestissimo Presto cluster.
      </Help.P>
    </Help.HelpSection>
  );
};

const UpdatingSecret = () => {
  const statusMessage =
    DataSources.constants.statusMessages[
      DataSources.constants.status.secret_updating
    ];
  return (
    <Help.HelpSection>
      <Help.HelpHeader>{statusMessage.title}</Help.HelpHeader>
      <Help.P>
        <Help.Strong>{statusMessage.description}</Help.Strong>
      </Help.P>
      <Help.P>
        You cannot attach a data source to Presto cluster until the
        authentication credentials are stored in the secret manager.
      </Help.P>
    </Help.HelpSection>
  );
};

const DeletingSecret = () => {
  const statusMessage =
    DataSources.constants.statusMessages[
      DataSources.constants.status.secret_deleting
    ];
  return (
    <Help.HelpSection>
      <Help.HelpHeader>{statusMessage.title}</Help.HelpHeader>
      <Help.P>
        <Help.Strong>{statusMessage.description}</Help.Strong>
      </Help.P>
      <Help.P>
        You cannot attach a data source being deleted to Presto cluster.
      </Help.P>
    </Help.HelpSection>
  );
};
const UpdateOrDeleteSecretFailed = (status, errorMessages = []) => {
  const statusMessage = DataSources.constants.statusMessages[status];
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>{statusMessage.title}</Help.HelpHeader>
        <Help.P>
          <Help.Strong>{statusMessage.description}</Help.Strong>
        </Help.P>
        {errorMessages?.map((m) => {
          if (typeof m === 'string') {
            return <Help.P key={m}>{m}</Help.P>;
          }
          return <Help.P key={m}>{m.errorSummary || m.errorMessage}</Help.P>;
        })}
      </Help.HelpSection>
    );
  };
};

const module = {
  GlueLakeFormation,
  Prestissimo,
  PrestissimoGlueWithLakeFormation,
  RangerHive,
  UpdatingSecret,
  DeletingSecret,
  UpdateOrDeleteSecretFailed,
};

export default module;
