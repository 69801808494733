import Big from 'big.js';
import { makeReducer } from '../../utils/reducer';
import auth from '../../auth';
// doing this to avoid circluar dependency
import * as dataSourcesActionTypes from '../DataSources/actionTypes';
// doing this to avoid circluar dependency
import * as prestoUsersActionTypes from '../PrestoUsers/actionTypes';
// doing this to avoid circluar dependency
import * as authorizationServicesActionTypes from '../AuthorizationServices/actionTypes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import { dInstanceTypeRegex } from '../../utils/regex';

const initialState = {
  prestoClusters: null,
  prestoClustersDataSourceIds: null,
  loadingInProgress: false,
  submitInProgress: false,
  error: null,
  actionStatus: new Map(),
};

const mapPrestoCluster = (inputModel) => {
  const viewModel = {
    ...inputModel,
    prestoConfiguration: {
      ...inputModel.prestoConfiguration,
    },
    clusterConfiguration: {
      ...inputModel.clusterConfiguration,
    },
    statusDate: new Date(Date.parse(inputModel.statusDate)),
    statusDateStr: inputModel.statusDate,
    isDInstanceType:
      inputModel.clusterConfiguration &&
      inputModel.clusterConfiguration.instanceType &&
      dInstanceTypeRegex.test(inputModel.clusterConfiguration.instanceType),
  };

  if (
    inputModel.clusterConfiguration.enableDataCache &&
    inputModel.clusterConfiguration.dataCacheDiskSizePerNode
  ) {
    const cacheType = inputModel.clusterConfiguration.enableDataCache;

    if (['alluxio-lite', 'rubix'].includes(cacheType)) {
      const { dataCacheDiskSizePerNode } = inputModel.clusterConfiguration;

      if (dataCacheDiskSizePerNode) {
        const multiplier = cacheType === 'alluxio-lite' ? 0.95 : 0.8;
        viewModel.clusterConfiguration.totalDataCacheDiskSizePerNode = Big(
          dataCacheDiskSizePerNode,
        )
          .times(inputModel.clusterConfiguration.workerNodes)
          .times(multiplier)
          .round(1);
      }
    }
  }

  if (
    inputModel.clusterConfiguration.enableFragmentResultCache &&
    inputModel.clusterConfiguration.fragmentResultCacheDiskSizePerNode
  ) {
    const { fragmentResultCacheDiskSizePerNode } =
      inputModel.clusterConfiguration;

    if (fragmentResultCacheDiskSizePerNode) {
      viewModel.clusterConfiguration.intermediateResultSetCache = Big(
        fragmentResultCacheDiskSizePerNode,
      )
        .times(inputModel.clusterConfiguration.workerNodes)
        .times(0.95)
        .round(1);
    }
  }

  if (
    inputModel.clusterConfiguration.customPluginBucket &&
    inputModel.clusterConfiguration.customPluginBucketPrefix
  ) {
    const { customPluginBucket, customPluginBucketPrefix } =
      inputModel.clusterConfiguration;
    if (customPluginBucket) {
      viewModel.clusterConfiguration.customPluginBucket = customPluginBucket;
    }
    if (customPluginBucket) {
      viewModel.clusterConfiguration.customPluginBucketPrefix =
        customPluginBucketPrefix;
    }
  }

  return viewModel;
};

const onNavigation = (draft) => {
  draft.submitInProgress = false;
  draft.loadingInProgress = false;
  draft.error = null;

  const newActionStatus = new Map();
  draft.actionStatus.forEach((value, key) => {
    if (value.inProgress) {
      newActionStatus[key] = { ...value };
    }
  });
  draft.actionStatus = newActionStatus;
};

const getStart = (draft) => {
  draft.loadingInProgress = true;
};

const getAllFailure = (draft) => {
  // TODO: display error message?
  draft.loadingInProgress = false;
};

const get = (draft, action) => {
  if (!draft.prestoClusters) {
    draft.prestoClusters = new Map();
  }
  const data = mapPrestoCluster(action.payload.apiResult.body);
  draft.prestoClusters.set(action.payload.params.prestoClusterId, data);
};

const getFailure = (draft, action) => {
  if (!draft.prestoClusters) {
    draft.prestoClusters = new Map();
  }
  draft.prestoClusters.set(action.payload.params.prestoClusterId, 'not found');
};

const getPartial = (draft, action) => {
  if (!draft.prestoClusters) {
    draft.prestoClusters = new Map();
  }
  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    const data = mapPrestoCluster(results[i]);
    draft.prestoClusters.set(results[i].prestoClusterId, data);
  }
};

const getAll = (draft, action) => {
  if (draft.prestoClusters) {
    draft.prestoClusters.clear();
  }

  getPartial(draft, action);

  draft.loadingInProgress = false;
};

const getDataSources = (draft, action) => {
  if (!draft.prestoClustersDataSourceIds) {
    draft.prestoClustersDataSourceIds = new Map();
  }

  const dataSourceIds = action.payload.apiResult.body.results.map((ds) => {
    return ds.dataSourceId;
  });
  draft.prestoClustersDataSourceIds.set(
    action.payload.params.prestoClusterId,
    dataSourceIds,
  );
};

const getDataSourcesFailure = (draft, action) => {
  if (!draft.prestoClustersDataSourceIds) {
    draft.prestoClustersDataSourceIds = new Map();
  }
  draft.prestoClustersDataSourceIds.set(
    action.payload.params.prestoClusterId,
    [],
  );
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.error = null;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.error = null;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const actionStart = (draft, action) => {
  const status = {
    ...action.payload.actionInfo,
    success: false,
    inProgress: true,
  };
  draft.actionStatus.set(
    action.payload.prestoClusterId || action.payload.params.prestoClusterId,
    status,
  );
};

const actionFailure = (draft, action) => {
  const oldStatus = draft.actionStatus.get(
    action.payload.params.prestoClusterId,
  );
  if (!oldStatus) {
    return;
  }

  if (oldStatus.actionId === action.payload.actionInfo.actionId) {
    const status = {
      ...oldStatus,
      success: false,
      inProgress: false,
      error: action.payload.apiResult.body.errorMessage,
    };
    draft.actionStatus.set(action.payload.params.prestoClusterId, status);
  }
};

const patchWorkerNodesSuccess = (prestoCluster, action) => {
  const newPrestoCluster = {
    ...prestoCluster,
    clusterConfiguration: {
      ...prestoCluster.clusterConfiguration,
      workerNodes: action.payload.requestBody.workerNodes,
    },
  };
  return newPrestoCluster;
};

const actionSuccess = (newStatus, updateModel) => {
  return (draft, action) => {
    const oldStatus = draft.actionStatus.get(
      action.payload.params.prestoClusterId,
    );
    if (!oldStatus) {
      return;
    }
    if (oldStatus.actionId === action.payload.actionInfo.actionId) {
      const status = {
        ...oldStatus,
        success: true,
        inProgress: false,
        error: null,
      };
      draft.actionStatus.set(action.payload.params.prestoClusterId, status);
    }

    const prestoCluster = draft.prestoClusters.get(
      action.payload.params.prestoClusterId,
    );
    const newPrestoCluster = {
      ...prestoCluster,
      errorMessages: null,
    };

    if (newStatus) {
      newPrestoCluster.status = newStatus;
    }

    draft.prestoClusters.set(
      action.payload.params.prestoClusterId,
      updateModel ? updateModel(newPrestoCluster, action) : newPrestoCluster,
    );
  };
};

const putDataSourcesSuccess = (draft, action) => {
  draft.prestoClustersDataSourceIds.delete(
    action.payload.params.prestoClusterId,
  );

  actionSuccess(constants.status.restarting)(draft, action);
};

const signOut = () => {
  return { ...initialState };
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.get.failure]: getFailure,
  [authorizationServicesActionTypes.getPrestoClusters.success]: getPartial,
  [dataSourcesActionTypes.getPrestoClusters.success]: getPartial,
  [prestoUsersActionTypes.getPrestoClustersForPrestoUser.success]: getPartial,
  [actionTypes.getDataSources.success]: getDataSources,
  [actionTypes.getDataSources.failure]: getDataSourcesFailure,
  [actionTypes.getAll.request]: getStart,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.patch.request]: submitStart,
  [actionTypes.patch.success]: submitSuccess,
  [actionTypes.patch.failure]: submitFailure,
  [actionTypes.remove.request]: actionStart,
  [actionTypes.remove.success]: actionSuccess(constants.status.destroying),
  [actionTypes.remove.failure]: actionFailure,
  [actionTypes.putRestart.request]: actionStart,
  [actionTypes.putRestart.success]: actionSuccess(constants.status.restarting),
  [actionTypes.putRestart.failure]: actionFailure,
  [actionTypes.putStart.request]: actionStart,
  [actionTypes.putStart.success]: actionSuccess(constants.status.starting),
  [actionTypes.putStart.failure]: actionFailure,
  [actionTypes.putStop.request]: actionStart,
  [actionTypes.putStop.success]: actionSuccess(constants.status.stopping),
  [actionTypes.putStop.failure]: actionFailure,
  [actionTypes.patchWorkerNodes.request]: actionStart,
  [actionTypes.patchWorkerNodes.success]: actionSuccess(
    constants.status.modifying,
    patchWorkerNodesSuccess,
  ),
  [actionTypes.patchWorkerNodes.failure]: actionFailure,
  [actionTypes.putDataSources.request]: actionStart,
  [actionTypes.putDataSources.success]: putDataSourcesSuccess,
  [actionTypes.putDataSources.failure]: actionFailure,
  [actionTypes.putAutoScalingPolicy.request]: actionStart,
  [actionTypes.putAutoScalingPolicy.success]: actionSuccess(),
  [actionTypes.putAutoScalingPolicy.failure]: actionFailure,
  [actionTypes.putEnableSpot.request]: actionStart,
  [actionTypes.putEnableSpot.success]: actionSuccess(),
  [actionTypes.putEnableSpot.failure]: actionFailure,
  [actionTypes.putGroupedConfig.request]: actionStart,
  [actionTypes.putGroupedConfig.success]: actionSuccess(),
  [actionTypes.putGroupedConfig.failure]: actionFailure,
  [actionTypes.putPrestoUsers.request]: actionStart,
  [actionTypes.putPrestoUsers.success]: actionSuccess(),
  [actionTypes.putPrestoUsers.failure]: actionFailure,
  [actionTypes.submitCredentials]: actionStart,
  [actionTypes.putCredentials.success]: actionSuccess(),
  [actionTypes.putCredentials.failure]: actionFailure,
  [actionTypes.putExportLogs.request]: actionStart,
  [actionTypes.putExportLogs.success]: actionSuccess(
    constants.status.modifying,
  ),
  [actionTypes.putExportLogs.failure]: actionFailure,
  [actionTypes.putExportDebuggingInfo.request]: actionStart,
  [actionTypes.putExportDebuggingInfo.success]: actionSuccess(
    constants.status.modifying,
  ),
  [actionTypes.putExportDebuggingInfo.failure]: actionFailure,
  [actionTypes.putTriggerOnDemandPrestoDebugging.request]: actionStart,
  [actionTypes.putTriggerOnDemandPrestoDebugging.success]: actionSuccess(
    constants.status.modifying,
  ),
  [actionTypes.putTriggerOnDemandPrestoDebugging.failure]: actionFailure,
  [auth.actionTypes.signOut]: signOut,
};

export default makeReducer(initialState, actionReducers);
