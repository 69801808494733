import { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string, number } from 'prop-types';
import { unstable_usePrompt as usePrompt, useNavigate } from 'react-router-dom';
import DataSources from '../../DataSources';
import Page from '../../Page';
import PrestoClusters from '../../PrestoClusters';
import PrestoUsers from '../../PrestoUsers';
import IdentityProviders from '../../IdentityProviders';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import useRouteBuilder from '../../../router/useRouteBuilder';
import TitleNav from './TitleNav';
import Information from './Information';
// import ClusterSettings from './ClusterSettings';
// import Integrations from './Integrations';
import AttachedDataSources from './AttachedDataSources';
import AttachedPrestoUsers from './AttachedPrestoUsers';
import AttachedIdentityProvider from '../../IdentityProviders/components/AttachedIdentityProvider';
import AutoScaling from './AutoScaling';
// import Workspaces from './Workspaces';
// import EventLog from './EventLog';
import ClusterStorageInformation from './ClusterStorageInformation';
import ConnectionDetails from './ConnectionDetails';
import DangerZone from './DangerZone';
import Support from './Support';

const display = {
  information: 'Information',
  connectionDetails: 'Connection Details',
  // clusterSettings: 'Cluster settings',
  // integrations: 'Integrations',
  dataSources: 'Data Sources',
  // workspaces: 'Workspaces',
  // eventLog: 'Event log',
  support: 'Support',
  dangerZone: 'Danger Zone',
  confirmCancel: 'Are you sure? Your changes will be lost.',
};

const navTabs = [
  display.information,
  display.connectionDetails,
  // display.clusterSettings,
  // display.integrations,
  display.dataSources,
  // display.workspaces,
  // display.eventLog,
  display.support,
  display.dangerZone,
];

const PrestoCluster = ({
  loadPrestoCluster,
  prestoClusterId,
  prestoCluster,
  notFound,
  deleteCluster,
  restartCluster,
  startCluster,
  stopCluster,
  setWorkerNodes,
  exportLogs,
  actionStatus,
  usePop,
  dataSources,
  loadDataSources,
  awsEBSCacheVolumesUrl,
  customPluginBucketLink,
  prestoUsers,
  prestoUsersLoading,
  loadPrestoUsers,
  identityProvider,
  identityProviderLoading,
  loadIdentityProviders,
  downloadLinks,
  prestoClusterSubdomain,
  maxWorkerNodeCount,
  exportDebuggingInfo,
  showSendDebugging,
  enableEnableSpot,
}) => {
  useEffect(() => {
    loadPrestoCluster(prestoClusterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestoClusterId]);

  const [actionInProgress, setActionInProgress] = useState(false);

  const showPrompt = useCallback(() => {
    return actionInProgress;
  }, [actionInProgress]);

  usePrompt({ when: showPrompt, message: display.confirmCancel });

  const notFoundRoute = useRouteBuilder('/notfound');
  const navigate = useNavigate();
  useEffect(() => {
    if (notFound) {
      navigate(notFoundRoute, { replace: true });
    }
  }, [notFound, notFoundRoute, navigate]);

  if (!prestoCluster || notFound) {
    return <Page.Loading />;
  }

  const enabledOAuthIdentityProvider =
    prestoCluster?.clusterConfiguration?.oauth2Enabled;

  return (
    <ActionInProgressStateContext.Provider value={actionInProgress}>
      <ActionInProgressSetStateContext.Provider value={setActionInProgress}>
        <Page.Page>
          <Page.InPageNavigation navTabs={navTabs}>
            <Page.PageHeaderWithNav>
              <div>
                <TitleNav
                  name={prestoCluster.name}
                  status={prestoCluster.status}
                />
              </div>
              <Page.PageNavigation navTabs={navTabs} />
            </Page.PageHeaderWithNav>
            <Page.Scroll>
              <Information
                navTarget={display.information}
                prestoCluster={prestoCluster}
                startCluster={startCluster}
                setWorkerNodes={setWorkerNodes}
                actionStatus={actionStatus}
                awsEBSCacheVolumesUrl={awsEBSCacheVolumesUrl}
                customPluginBucketLink={customPluginBucketLink}
                maxWorkerNodeCount={maxWorkerNodeCount}
                enableEnableSpot={enableEnableSpot}
              />
              <AutoScaling
                prestoClusterId={prestoClusterId}
                autoScaling={prestoCluster.autoScaling}
                status={prestoCluster.status}
              />
              <ConnectionDetails
                navTarget={display.connectionDetails}
                prestoClusterUrl={
                  prestoCluster.prestoConfiguration.prestoClusterUrl
                }
                jdbcConnectionUrl={
                  prestoCluster.prestoConfiguration.jdbcConnectionUrl
                }
                username={
                  (!prestoCluster.prestoUserIds ||
                    prestoCluster.prestoUserIds.length === 0) &&
                  prestoCluster.credentials &&
                  prestoCluster.credentials.length > 0
                    ? prestoCluster.credentials[0].username
                    : null
                }
                password={
                  (!prestoCluster.prestoUserIds ||
                    prestoCluster.prestoUserIds.length === 0) &&
                  prestoCluster.credentials &&
                  prestoCluster.credentials.length > 0
                    ? prestoCluster.credentials[0].password
                    : null
                }
                prestoClusterId={prestoCluster.prestoClusterId}
                status={prestoCluster.status}
                downloadLinks={downloadLinks}
              />
              {enabledOAuthIdentityProvider === true && (
                <AttachedIdentityProvider
                  loadingInProgress={identityProviderLoading}
                  identityProvider={identityProvider}
                  loadIdentityProviders={loadIdentityProviders}
                  prestoClusterSubdomain={prestoClusterSubdomain}
                />
              )}
              <AttachedPrestoUsers
                prestoUsers={prestoUsers}
                loading={prestoUsersLoading}
                loadPrestoUsers={loadPrestoUsers}
                prestoCluster={prestoCluster}
                prestoClusterId={prestoClusterId}
              />
              {prestoCluster.clusterConfiguration.enableAhanaManagedHMS && (
                <ClusterStorageInformation
                  navTarget={display.ahanaManagedCatalog}
                  hmsWarehouseDir={
                    prestoCluster.prestoConfiguration.hmsWarehouseDir
                  }
                />
              )}
              <AttachedDataSources
                navTarget={display.dataSources}
                dataSources={dataSources}
                loadDataSources={loadDataSources}
                prestoClusterId={prestoClusterId}
                prestoCluster={prestoCluster}
              />
              {/* <ClusterSettings navTarget={display.clusterSettings} /> */}
              {/* <Integrations navTarget={display.integrations} /> */}
              {/* <Workspaces navTarget={display.workspaces} /> */}
              {/* <EventLog navTarget={display.eventLog} /> */}
              <Support
                navTarget={display.support}
                prestoClusterId={prestoClusterId}
                prestoCluster={prestoCluster}
                actionStatus={actionStatus}
                exportLogs={exportLogs}
                exportDebuggingInfo={exportDebuggingInfo}
                showSendDebugging={showSendDebugging}
              />
              <DangerZone
                navTarget={display.dangerZone}
                prestoClusterId={prestoClusterId}
                prestoCluster={prestoCluster}
                actionStatus={actionStatus}
                stopCluster={stopCluster}
                restartCluster={restartCluster}
                deleteCluster={deleteCluster}
                usePop={usePop}
              />
            </Page.Scroll>
          </Page.InPageNavigation>
        </Page.Page>
      </ActionInProgressSetStateContext.Provider>
    </ActionInProgressStateContext.Provider>
  );
};

PrestoCluster.defaultProps = {
  prestoCluster: null,
  dataSources: null,
  notFound: false,
  actionStatus: undefined,
  usePop: true,
  awsEBSCacheVolumesUrl: undefined,
  customPluginBucketLink: undefined,
  prestoUsers: undefined,
  prestoUsersLoading: false,
  identityProvider: null,
  identityProviderLoading: false,
  downloadLinks: null,
  prestoClusterSubdomain: null,
  enableEnableSpot: false,
};

PrestoCluster.propTypes = {
  loadPrestoCluster: func.isRequired,
  prestoClusterId: string.isRequired,
  prestoCluster: shape({
    name: string.isRequired,
  }),
  notFound: bool,
  deleteCluster: func.isRequired,
  restartCluster: func.isRequired,
  startCluster: func.isRequired,
  stopCluster: func.isRequired,
  setWorkerNodes: func.isRequired,
  exportLogs: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  usePop: bool,
  dataSources: arrayOf(DataSources.propTypes.DataSource),
  loadDataSources: func.isRequired,
  awsEBSCacheVolumesUrl: string,
  customPluginBucketLink: string,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  prestoUsersLoading: bool,
  loadPrestoUsers: func.isRequired,
  identityProvider: IdentityProviders.propTypes.IdentityProvider,
  identityProviderLoading: bool,
  loadIdentityProviders: func.isRequired,
  downloadLinks: PrestoClusters.propTypes.DownloadLinks,
  prestoClusterSubdomain: string,
  maxWorkerNodeCount: number.isRequired,
  exportDebuggingInfo: func.isRequired,
  showSendDebugging: bool.isRequired,
  enableEnableSpot: bool,
};

export default PrestoCluster;
