import { useCallback, useMemo } from 'react';
import { bool, string, arrayOf } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoUsers from '../../PrestoUsers';
import PrestoClusters from '../../PrestoClusters';
import Credentials from './Credentials';
import PendingStatus from './PendingStatus';
import PageHelp from './PageHelp';
import { simpleStatus, getSimpleStatus } from '../../PrestoClusters/constants';

const display = {
  header: 'Edit Presto User',
  updateButton: 'Modify Presto User',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingCredentials: 'Updating Presto user...',
};

const UpdatePrestoUserForm = ({
  submitInProgress,
  submitSuccess,
  error,
  prestoUser,
  prestoClusters,
  canModify,
  prestoUserId,
  usePop,
}) => {
  const pendingCluster = useMemo(() => {
    if (prestoClusters) {
      const pendingClusters = prestoClusters.filter((pc) => {
        return getSimpleStatus(pc.status) === simpleStatus.pending;
      });

      if (pendingClusters.length > 0) {
        return pendingClusters[0];
      }
    }

    return null;
  }, [prestoClusters]);

  const getRoute = useCallback(() => {
    return PrestoUsers.routes.getManagePrestoUserRoute(prestoUserId);
  }, [prestoUserId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {pendingCluster && (
            <Banner scrollIntoView>
              <PendingStatus
                name={pendingCluster.name}
                status={pendingCluster.status}
              />
            </Banner>
          )}
          {error && <Banner scrollIntoView title={error} />}
          <Credentials prestoUser={prestoUser} disabled={!canModify} />
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              disabled={submitInProgress || !canModify}
              label={
                !submitInProgress
                  ? display.updateButton
                  : display.savingCredentials
              }
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

UpdatePrestoUserForm.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  error: undefined,
  prestoClusters: null,
  canModify: true,
  usePop: false,
};

UpdatePrestoUserForm.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  error: string,
  prestoUser: PrestoUsers.propTypes.PrestoUser.isRequired,
  prestoClusters: arrayOf(PrestoClusters.propTypes.PrestoCluster),
  canModify: bool,
  prestoUserId: string.isRequired,
  usePop: bool,
};

export default UpdatePrestoUserForm;
