import { useState, useCallback, useContext, useEffect } from 'react';
import { string, func, number, shape, bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import useRouteBuilder from '../../../router/useRouteBuilder';
import PrestoClusters from '../../PrestoClusters';
import { useEnableFeature } from '../../utils/featureHooks';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';

const display = {
  title: 'Danger Zone',
  stopCluster: 'Stop Cluster',
  stopClusterButton: 'Stop Cluster',
  stopClusterDescription:
    'All queries running on the cluster will fail. The cluster will be moved to inactive state. All IBM watsonx.data metadata and IBM watsonx.data-managed catalog data in Hive Metastore will be maintained and available on cluster start.',
  stopClusterConfirm: 'This action will stop the entire Presto cluster.',
  stopClusterConfirmDescription:
    'Any queries running on the cluster will fail.',
  restartCluster: 'Restart Cluster',
  restartClusterButton: 'Restart Cluster',
  restartClusterDescription:
    'This will restart the Presto cluster. All queries running will fail. The cluster will be moved to pending state and will be unusable until it moves back to active state.',
  restartClusterConfirm: 'This action will restart the entire Presto cluster.',
  restartClusterConfirmDescription:
    'Any queries running on the cluster will fail. The cluster will be ready for use once in the active state.',
  deleteCluster: 'Delete Cluster',
  deleteClusterButton: 'Delete Cluster',
  deleteClusterDescription:
    'The will completely delete the Presto cluster. All queries running will fail. All information about the cluster and the IBM watsonx.data-managed catalog information will be deleted.',
  deleteClusterConfirm: 'This action will delete the entire Presto cluster.',
  deleteClusterConfirmDescription:
    'Any queries running on the cluster will fail. All configurations and metadata, including the IBM watsonx.data managed Hive Metastore, will be destroyed.',
  deleteClusterWithoutHiveMetastoreConfirmDescription:
    'Any queries running on the cluster will fail. All configurations and metadata will be destroyed.',
  deleteQueryLogBucketButton:
    'Delete Query Log S3 Bucket attached to this cluster',
  deleteHiveBucketButton:
    'Delete Cluster Data S3 Bucket attached to this cluster',
  cancelButton: 'Cancel',
};

const DangerZone = ({
  navTarget,
  prestoClusterId,
  prestoCluster,
  actionStatus,
  stopCluster,
  restartCluster,
  deleteCluster,
  usePop,
}) => {
  const enableRestart = useEnableFeature();
  const enableStopAndDelete = useEnableFeature({
    requireActiveComputePlane: true,
    requireComputePlaneGreaterThanMinimumVersion: false,
    requireValidSubscription: false,
  });
  const actionInProgress = useContext(ActionInProgressStateContext);
  const setActionInProgress = useContext(ActionInProgressSetStateContext);

  const [confirmStop, setConfirmStop] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onStop = useCallback(() => {
    setActionInProgress(true);
    setConfirmStop(true);
  }, [setActionInProgress]);
  const onRestart = useCallback(() => {
    setActionInProgress(true);
    setConfirmRestart(true);
  }, [setActionInProgress]);
  const onDelete = useCallback(() => {
    setActionInProgress(true);
    setConfirmDelete(true);
  }, [setActionInProgress]);

  const onConfirmStop = useCallback(() => {
    const actionId = Date.now();
    setConfirmStop(actionId);
    stopCluster(prestoClusterId, actionId);
    setActionInProgress(false);
  }, [prestoClusterId, stopCluster, setActionInProgress]);

  const onConfirmRestart = useCallback(() => {
    const actionId = Date.now();
    setConfirmRestart(actionId);
    restartCluster(prestoClusterId, actionId);
    setActionInProgress(false);
  }, [prestoClusterId, restartCluster, setActionInProgress]);

  const onConfirmDelete = useCallback(
    (values) => {
      const actionId = Date.now();
      setConfirmDelete(actionId);
      deleteCluster({ prestoClusterId, actionId, ...values });
      setActionInProgress(false);
    },
    [deleteCluster, prestoClusterId, setActionInProgress],
  );

  const onCancel = useCallback(() => {
    setActionInProgress(false);
    setConfirmStop(false);
    setConfirmRestart(false);
    setConfirmDelete(false);
  }, [setActionInProgress]);

  const navigate = useNavigate();
  const prestoClusterRoute = useRouteBuilder(PrestoClusters.routes.getRoute);
  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmStop
    ) {
      setConfirmStop(false);
    }
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmRestart
    ) {
      setConfirmRestart(false);
    }
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmDelete
    ) {
      if (actionStatus.success) {
        // nav back
        if (usePop) {
          navigate(-1);
        } else {
          navigate(prestoClusterRoute, { replace: true });
        }
      }
      setConfirmDelete(false);
    }
  }, [
    actionStatus,
    confirmStop,
    confirmRestart,
    confirmDelete,
    navigate,
    usePop,
    prestoClusterRoute,
  ]);

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    (actionStatus.name === 'stop' ||
      actionStatus.name === 'restart' ||
      actionStatus.name === 'delete');

  const showStop =
    prestoCluster.status === PrestoClusters.constants.status.active ||
    prestoCluster.status === PrestoClusters.constants.status.stop_failure;

  const showRestart =
    prestoCluster.status === PrestoClusters.constants.status.active ||
    prestoCluster.status === PrestoClusters.constants.status.restart_failure;
  const isPending =
    PrestoClusters.constants.getSimpleStatus(prestoCluster.status) ===
    PrestoClusters.constants.simpleStatus.pending;
  const style = showStop
    ? {
        '--row-count': 3,
      }
    : undefined;

  const hmsEnabled =
    prestoCluster &&
    prestoCluster.clusterConfiguration &&
    prestoCluster.clusterConfiguration.enableAhanaManagedHMS &&
    prestoCluster.clusterConfiguration.enableAhanaManagedHMS === true;

  return (
    <Page.BoxNavTarget navTarget={navTarget} error>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      {showActionStatusError && (
        <Page.InformationBanner>
          <Banner scrollIntoView title={actionStatus.error} />
        </Page.InformationBanner>
      )}
      <Page.DangerZone style={style}>
        {(showStop || showRestart || isPending) && confirmStop !== true && (
          <>
            <div>
              <h3>{display.stopCluster}</h3>
              <div>{display.stopClusterDescription}</div>
            </div>
            <div>
              <Form.ErrorButton
                label={display.stopClusterButton}
                onClick={onStop}
                disabled={
                  !showStop ||
                  isPending ||
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableStopAndDelete
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmStop
                }
              />
            </div>
          </>
        )}
        {confirmStop === true && (
          <Page.ConfirmDanger>
            <Banner
              title={display.stopClusterConfirm}
              details={display.stopClusterConfirmDescription}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                />
                <Form.PrimaryButton
                  onClick={onConfirmStop}
                  label={display.stopClusterButton}
                />
              </div>
            </Banner>
          </Page.ConfirmDanger>
        )}
        {(showRestart || showStop || isPending) && confirmRestart !== true && (
          <>
            <div>
              <h3>{display.restartCluster}</h3>
              <div>{display.restartClusterDescription}</div>
            </div>
            <div>
              <Form.ErrorButton
                label={display.restartClusterButton}
                onClick={onRestart}
                disabled={
                  !showRestart ||
                  isPending ||
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableRestart
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmRestart
                }
              />
            </div>
          </>
        )}
        {confirmRestart === true && (
          <Page.ConfirmDanger>
            <Banner
              title={display.restartClusterConfirm}
              details={display.restartClusterConfirmDescription}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                />
                <Form.PrimaryButton
                  onClick={onConfirmRestart}
                  label={display.restartClusterButton}
                />
              </div>
            </Banner>
          </Page.ConfirmDanger>
        )}
        {confirmDelete !== true && (
          <>
            <div>
              <h3>{display.deleteCluster}</h3>
              <div>{display.deleteClusterDescription}</div>
            </div>
            <div>
              <Form.ErrorButton
                label={display.deleteClusterButton}
                onClick={onDelete}
                disabled={
                  isPending ||
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableStopAndDelete
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmDelete
                }
              />
            </div>
          </>
        )}
        {confirmDelete === true && (
          <Page.ConfirmDanger>
            <Banner
              title={display.deleteClusterConfirm}
              details={
                hmsEnabled
                  ? display.deleteClusterConfirmDescription
                  : display.deleteClusterWithoutHiveMetastoreConfirmDescription
              }
            >
              <Form.Form
                name='delete-presto-cluster'
                submitAction={onConfirmDelete}
              >
                <Form.CheckboxInput
                  name='deleteQueryLogBucket'
                  label={display.deleteQueryLogBucketButton}
                  mediumFont
                  compact
                />
                {hmsEnabled && (
                  <Form.CheckboxInput
                    name='deleteHiveBucket'
                    label={display.deleteHiveBucketButton}
                    mediumFont
                    compact
                  />
                )}
                <div className='buttons'>
                  <Form.SecondaryButton
                    onClick={onCancel}
                    label={display.cancelButton}
                  />
                  <Form.SubmitDestructive
                    label={display.deleteClusterButton}
                    fullWidth
                  />
                </div>
              </Form.Form>
            </Banner>
          </Page.ConfirmDanger>
        )}
      </Page.DangerZone>
    </Page.BoxNavTarget>
  );
};

DangerZone.defaultProps = {
  actionStatus: undefined,
  usePop: true,
};

DangerZone.propTypes = {
  navTarget: string.isRequired,
  prestoClusterId: string.isRequired,
  prestoCluster: shape({
    status: string.isRequired,
  }).isRequired,
  stopCluster: func.isRequired,
  restartCluster: func.isRequired,
  deleteCluster: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    inProgress: bool.isRequired,
  }),
  usePop: bool,
};

export default DangerZone;
