import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import {
  string,
  shape,
  number,
  instanceOf,
  func,
  bool,
  arrayOf,
} from 'prop-types';
import EditIcon from '../../../icons/Edit';
import Banner from '../../Banner';
import { Duration, Timespan } from '../../Date';
import Form from '../../Form';
import Page from '../../Page';
import PrestoClusters from '../../PrestoClusters';
import { SuccessBadge, WarningBadge } from '../../Badge';
import { useEnableFeature } from '../../utils/featureHooks';
import { onAccessibleClick } from '../../utils/accessibility';
import {
  ActionInProgressStateContext,
  ActionInProgressSetStateContext,
} from '../../utils/ActionInProgressContext';
import EditWorkerNodes from './EditWorkerNodes';
import { InformationGridDiv } from '../../Page/styledComponents';
import CacheStatus from './CacheStatus';
import PrestissimoBadge from './PrestissimoBadge';
import { TitleBadge, CustomPluginWrapperDiv } from './styledComponents';

const display = {
  title: 'Information',
  numberOfNodes: 'Number of worker nodes',
  currentNumberOfNodes: 'Current worker node count',
  uptime: 'Uptime',
  prestoVersion: 'Version of Presto',
  ahanaVersion: 'Version of watsonx.data',
  clusterCoordinatorInstanceType: 'Presto coordinator instance type',
  clusterWorkerInstanceType: 'Presto workers instance type',
  clusterHiveMetastoreInstanceType: 'Hive Metastore instance type',
  dataLakeIOCachestatus: 'Data Lake IO Cache status',
  totalIOCacheSize: 'Total IO Cache size',
  dataIOCache: 'Data IO Cache',
  cacheVolumes: 'Cache Volumes',
  customPluginBucket: 'Custom Plugin Bucket',
  concurrency: 'Concurrency',
  startButton: 'Start',
  errorDestroying: 'Error deleting',
  errorStopping: 'Error stopping',
  errorInitializing: 'Error initializing',
  errorRestarting: 'Error restarting',
  errorStarting: 'Error starting',
  errorInactive: 'Error modifying',
  viewEBSVolumes: 'View EBS Volumes',
  lowConcurrency: 'Low',
  highConcurrency: 'High',
  queryTerminationGracePeriod: 'Query termination grace period',
  fragmentResultCache: 'Intermediate Result Set Cache',
  spotWorkerNodes: 'Spot Worker Nodes',
  enabled: 'Enabled',
  disabled: 'Disabled',
};

const getErrorTitle = (status) => {
  switch (status) {
    case PrestoClusters.constants.status.init_failure:
      return display.errorInitializing;
    case PrestoClusters.constants.status.restart_failure:
      return display.errorRestarting;
    case PrestoClusters.constants.status.start_failure:
      return display.errorStarting;
    case PrestoClusters.constants.status.stop_failure:
      return display.errorStopping;
    case PrestoClusters.constants.status.destroy_failure:
      return display.errorDestroying;
    case PrestoClusters.constants.status.inactive_failure:
      return display.errorInactive;
    default:
      return null;
  }
};

const Information = ({
  navTarget,
  prestoCluster,
  startCluster,
  setWorkerNodes,
  actionStatus,
  awsEBSCacheVolumesUrl,
  customPluginBucketLink,
  maxWorkerNodeCount,
  enableEnableSpot,
}) => {
  const enableActions = useEnableFeature();
  const actionInProgress = useContext(ActionInProgressStateContext);
  const setActionInProgress = useContext(ActionInProgressSetStateContext);

  const [starting, setStarting] = useState(false);
  const onStart = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        const actionId = Date.now();
        setStarting(actionId);
        return startCluster(prestoCluster.prestoClusterId, actionId);
      });
    },
    [startCluster, prestoCluster],
  );
  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === starting
    ) {
      setStarting(false);
    }
  }, [actionStatus, starting]);

  const [editingWorkerNodes, setEditingWorkerNodes] = useState(false);

  const startEditWorkerNodes = useCallback(
    (event) => {
      return onAccessibleClick(event, () => {
        setActionInProgress(true);
        return setEditingWorkerNodes(true);
      });
    },
    [setActionInProgress],
  );
  const stopEditWorkerNodes = useCallback(() => {
    setActionInProgress(false);
    return setEditingWorkerNodes(false);
  }, [setActionInProgress]);

  const showStart =
    prestoCluster.status === PrestoClusters.constants.status.inactive ||
    prestoCluster.status === PrestoClusters.constants.status.start_failure;

  const showUpdateWorkerNodes =
    !prestoCluster.autoScaling &&
    (prestoCluster.status === PrestoClusters.constants.status.inactive ||
      prestoCluster.status === PrestoClusters.constants.status.active);

  const showEditEnableSpot =
    enableEnableSpot &&
    (prestoCluster.status === PrestoClusters.constants.status.inactive ||
      prestoCluster.status === PrestoClusters.constants.status.active);

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    (actionStatus.name === 'start' || actionStatus.name === 'editWorkerNodes');

  const showPrestoClusterError =
    (!actionStatus || !actionStatus.error) && prestoCluster.errorMessages;

  const style = useMemo(() => {
    return prestoCluster.clusterConfiguration.enableDataCache
      ? { paddingBottom: '40px' }
      : undefined;
  }, [prestoCluster]);

  const concurrency =
    prestoCluster.clusterConfiguration.concurrency === 'low'
      ? display.lowConcurrency
      : display.highConcurrency;

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      {showActionStatusError && (
        <Page.InformationBanner>
          <Banner scrollIntoView title={actionStatus.error} />
        </Page.InformationBanner>
      )}
      {showPrestoClusterError && (
        <Page.InformationBanner>
          <Banner scrollIntoView title={getErrorTitle(prestoCluster.status)}>
            <div>
              {prestoCluster.errorMessages.map((m) => {
                if (typeof m === 'string') {
                  return <p key={m}>{m}</p>;
                }
                return <p key={m}>{m.errorSummary || m.errorMessage}</p>;
              })}
            </div>
          </Banner>
        </Page.InformationBanner>
      )}
      <InformationGridDiv style={{ '--grid-columns': 3 }}>
        <div className='cell'>
          <div className='title'>{display.prestoVersion}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.prestoVersion}
            {prestoCluster.clusterConfiguration.workerContainerType ===
              'prestissimo' && (
              <TitleBadge>
                <PrestissimoBadge />
              </TitleBadge>
            )}
          </div>
        </div>
        <div className='cell'>
          {editingWorkerNodes && enableActions && (
            <EditWorkerNodes
              prestoClusterId={prestoCluster.prestoClusterId}
              currentNodeCount={
                prestoCluster.clusterConfiguration.desiredWorkerCount
              }
              actionStatus={actionStatus}
              close={stopEditWorkerNodes}
              setWorkerNodes={setWorkerNodes}
              autoScaling={prestoCluster.autoScaling}
              maxWorkerNodeCount={maxWorkerNodeCount}
            />
          )}
          {(!editingWorkerNodes || !enableActions) && (
            <>
              <div className='title'>
                {prestoCluster.autoScaling
                  ? display.currentNumberOfNodes
                  : display.numberOfNodes}
              </div>
              <div className='stretch'>
                <div className='data'>
                  {prestoCluster.clusterConfiguration.workerNodes}
                </div>
                {showUpdateWorkerNodes && (
                  <Form.TertiaryButton
                    onClick={startEditWorkerNodes}
                    disabled={
                      actionInProgress ||
                      (actionStatus && actionStatus.inProgress) ||
                      !enableActions
                    }
                  >
                    <div className='editIcon'>
                      <EditIcon />
                    </div>
                  </Form.TertiaryButton>
                )}
              </div>
            </>
          )}
        </div>
        <div className='cell'>
          <div className='title'>{display.uptime}</div>
          <div className='stretch'>
            <div className='data'>
              {(prestoCluster.status ===
                PrestoClusters.constants.status.active ||
                prestoCluster.status ===
                  PrestoClusters.constants.status.modifying) && (
                // eslint-disable-next-line react/jsx-indent
                <Duration startDate={prestoCluster.statusDate} />
              )}
              {prestoCluster.status !==
                PrestoClusters.constants.status.active &&
                prestoCluster.status !==
                  PrestoClusters.constants.status.modifying &&
                '-'}
            </div>
            {showStart && (
              <Form.SuccessButton
                label={display.startButton}
                onClick={onStart}
                disabled={
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableActions
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === starting
                }
              />
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.clusterCoordinatorInstanceType}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.prestoCoordinatorInstanceType}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.clusterWorkerInstanceType}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.instanceType}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>
            {display.clusterHiveMetastoreInstanceType}
          </div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.hmsInstanceType
              ? prestoCluster.clusterConfiguration.hmsInstanceType
              : '-'}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.concurrency}</div>
          <div className='stretch'>
            <div className='data'>
              {prestoCluster.clusterConfiguration.concurrency
                ? concurrency
                : '-'}
            </div>
            {prestoCluster.clusterConfiguration.concurrency && (
              <Page.TertiaryLink
                to={PrestoClusters.routes.getPrestoClusterGroupedConfigRoute(
                  prestoCluster.prestoClusterId,
                )}
                disabled={
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableActions ||
                  prestoCluster.status !==
                    PrestoClusters.constants.status.active
                }
              >
                <div className='editIcon'>
                  <EditIcon />
                </div>
              </Page.TertiaryLink>
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.queryTerminationGracePeriod}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration
              .prestoWorkerTerminationGracePeriod ? (
              <Timespan
                milliseconds={
                  prestoCluster.clusterConfiguration
                    .prestoWorkerTerminationGracePeriod * 1000
                }
              />
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.spotWorkerNodes}</div>
          <div className='stretch'>
            <div className='data'>
              {prestoCluster.clusterConfiguration.enableSpot ? (
                prestoCluster.clusterConfiguration.spotWorkerNodes
              ) : (
                <WarningBadge>{display.disabled}</WarningBadge>
              )}
            </div>
            {showEditEnableSpot && (
              <Page.TertiaryLink
                to={PrestoClusters.routes.getPrestoClusterEnableSpotRoute(
                  prestoCluster.prestoClusterId,
                )}
                disabled={
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !enableActions
                }
              >
                <div className='editIcon'>
                  <EditIcon />
                </div>
              </Page.TertiaryLink>
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.dataIOCache}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.enableDataCache &&
            prestoCluster.clusterConfiguration.totalDataCacheDiskSizePerNode ? (
              `${prestoCluster.clusterConfiguration.totalDataCacheDiskSizePerNode} GB`
            ) : (
              <CacheStatus />
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.fragmentResultCache}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.enableFragmentResultCache &&
            prestoCluster.clusterConfiguration.intermediateResultSetCache ? (
              `${prestoCluster.clusterConfiguration.intermediateResultSetCache} GB`
            ) : (
              <CacheStatus />
            )}
          </div>
        </div>
        <div className='cell' style={style}>
          <div className='title'>{display.cacheVolumes}</div>
          <div className='data'>
            {prestoCluster.isDInstanceType &&
              prestoCluster.clusterConfiguration.enableDataCache ===
                'alluxio-lite' &&
              'Instance Storage'}
            {(!prestoCluster.isDInstanceType &&
              prestoCluster.clusterConfiguration.enableDataCache ===
                'alluxio-lite') ||
            prestoCluster.clusterConfiguration.enableDataCache === 'rubix' ||
            (prestoCluster.clusterConfiguration.enableFragmentResultCache &&
              !prestoCluster.isDInstanceType) ? (
              <Page.PrimaryLink
                to={awsEBSCacheVolumesUrl}
                disabled={
                  !enableActions ||
                  prestoCluster.status ===
                    PrestoClusters.constants.status.inactive
                }
                external
                inlineDisabled
              >
                {display.viewEBSVolumes}
              </Page.PrimaryLink>
            ) : (
              !prestoCluster.clusterConfiguration.enableDataCache && '-'
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.customPluginBucket}</div>
          <div className='stretch'>
            {prestoCluster.clusterConfiguration.customPluginBucketPrefix ? (
              <div className='stretch'>
                <div className='data'>
                  <SuccessBadge>{display.enabled}</SuccessBadge>
                </div>
                <Page.TertiaryLink to={customPluginBucketLink} external newTab>
                  S3
                </Page.TertiaryLink>
              </div>
            ) : (
              <CustomPluginWrapperDiv>
                <WarningBadge>{display.disabled}</WarningBadge>
              </CustomPluginWrapperDiv>
            )}
          </div>
        </div>
        <div className='cell' />
        <div className='cell' />
      </InformationGridDiv>
    </Page.BoxNavTarget>
  );
};

Information.defaultProps = {
  actionStatus: undefined,
  awsEBSCacheVolumesUrl: undefined,
  customPluginBucketLink: undefined,
};

Information.propTypes = {
  navTarget: string.isRequired,
  prestoCluster: shape({
    prestoClusterId: string.isRequired,
    status: string.isRequired,
    statusDate: instanceOf(Date).isRequired,
    clusterConfiguration: shape({
      workerNodes: number.isRequired,
      instanceType: string.isRequired,
    }),
    credentials: arrayOf(
      shape({
        username: string.isRequired,
      }),
    ),
    autoScaling: shape({
      type: string.isRequired,
    }),
  }).isRequired,
  startCluster: func.isRequired,
  setWorkerNodes: func.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  awsEBSCacheVolumesUrl: string,
  customPluginBucketLink: string,
  maxWorkerNodeCount: number.isRequired,
  enableEnableSpot: bool.isRequired,
};

export default Information;
