import { Netmask } from 'netmask';
import logger from '../../utils/logger';
import { ipRegex } from '../../utils/regex';

const cidrErrorMessages = {
  invalidBitmaskError:
    'The CIDR block bitmask should be between 16 and 24 inclusive',
  privateNetworkError:
    'The CIDR block must exist in a private network address block (10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16).',
  malformedError: (expected) => {
    return `Invalid CIDR block. Did you mean ${expected}?`;
  },
  invalidCidr: 'The supplied value is not a CIDR block',
};

export const validateCIDR = (value) => {
  try {
    const cidrParts = value.split('/');
    if (cidrParts.length !== 2 || !ipRegex.test(cidrParts[0])) {
      return cidrErrorMessages.invalidCidr;
    }

    const block = new Netmask(value);

    const publicA = new Netmask('10.0.0.0/8');
    if (!publicA.contains(block.first)) {
      const publicB = new Netmask('172.16.0.0/12');
      if (!publicB.contains(block.first)) {
        const publicC = new Netmask('192.168.0.0/16');
        if (!publicC.contains(block.first)) {
          return cidrErrorMessages.privateNetworkError;
        }
      }
    }

    // We are allowing a maximum of 25 for a specific client, but the error message will still indicate that
    // 24 is the maximum, as this is only for a special case
    if (block.bitmask < 16 || block.bitmask > 25) {
      return cidrErrorMessages.invalidBitmaskError;
    }

    if (block.base !== cidrParts[0]) {
      return cidrErrorMessages.malformedError(block.base);
    }

    return true;
  } catch (e) {
    logger.info(e);
    return cidrErrorMessages.invalidCidr;
  }
};

export const validateSubnets = (value) => {
  if (value.length < 2) {
    return 'You must provide at least two subnets.';
  }

  return true;
};

/**
 * Looks at the data we are going to update on the compute plane. If certain
 * data is being updated then we need to have the user verify there role again
 * before they can move past Step 4.
 *
 * @param {object} computePlaneUpdateData
 */
export const shouldReverifyRole = (
  computePlaneUpdateData,
  originalComputePlane,
  orignialAwsAccountId,
) => {
  if (
    Object.keys(computePlaneUpdateData).includes('isBYOVPC') &&
    computePlaneUpdateData.isBYOVPC !==
      originalComputePlane?.computeConfiguration?.isBYOVPC
  ) {
    return true;
  }

  if (
    computePlaneUpdateData.awsAccountId &&
    computePlaneUpdateData.awsAccountId !== orignialAwsAccountId
  ) {
    return true;
  }

  if (
    computePlaneUpdateData.vpcId &&
    computePlaneUpdateData.vpcId !==
      originalComputePlane?.awsCloudConfiguration?.vpcId
  ) {
    return true;
  }

  return false;
};
