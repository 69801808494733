import styled from 'styled-components/macro';
import { colors } from '../../../css';
import * as constants from '../constants';

export const StatusDotDiv = styled.div`
  margin-left: 20px;
  border-radius: 50%;
  height: 12px;
  width: 12px;

  &.${constants.status.active} {
    border-color: ${colors.success};
    background-color: ${colors.success};
  }

  &.${constants.status.init_failure},
    &.${constants.status.destroy_failure},
    &.${constants.status.restart_failure},
    &.${constants.status.start_failure},
    &.${constants.status.stop_failure},
    &.${constants.status.inactive},
    &.${constants.status.inactive_failure},
    &.${constants.status.destroyed} {
    border-color: ${colors.error};
    background-color: ${colors.error};
  }

  &.${constants.status.initializing},
    &.${constants.status.destroying},
    &.${constants.status.restarting},
    &.${constants.status.starting},
    &.${constants.status.stopping},
    &.${constants.status.modifying} {
    border-color: ${colors.warning};
    background-color: ${colors.warning};
  }
`;

export const NoClustersDiv = styled.div`
  border-top: 1px solid ${colors.border};
  padding: 24px;
`;
