import { createSelector } from 'reselect';
import { simpleStatus, getSimpleStatus } from '../PrestoClusters/constants';

export const getPrestoUserId = (state, props) => {
  return props.params.prestoUserId;
};

const prestoUserSelector = (state) => {
  return state.prestoUsers.prestoUsers;
};

const removeNotFound = (prestoUser) => {
  if (prestoUser === 'not found') {
    return null;
  }
  return prestoUser;
};

export const getPrestoUsers = createSelector(
  prestoUserSelector,
  (prestoUsers) => {
    if (!prestoUsers) return null;
    let prestoUsersArray = Array.from(prestoUsers.values(), removeNotFound);
    prestoUsersArray = prestoUsersArray.filter((b) => {
      return b !== null;
    });

    return [...prestoUsersArray.values()].sort((a, b) => {
      return a.username.localeCompare(b.username);
    });
  },
);

export const getPrestoUser = (state, props) => {
  const prestoUserId = getPrestoUserId(state, props);
  if (!state.prestoUsers.prestoUsers) {
    return null;
  }
  const ds = state.prestoUsers.prestoUsers.get(prestoUserId);
  if (ds === 'not found') {
    return null;
  }
  return ds;
};

export const getIsNotFound = (state, props) => {
  const prestoUserId = getPrestoUserId(state, props);
  if (!state.prestoUsers.prestoUsers) {
    return null;
  }
  const ds = state.prestoUsers.prestoUsers.get(prestoUserId);
  if (ds === 'not found') {
    return true;
  }
  return false;
};

export const getLoadingInProgress = (state) => {
  return state.prestoUsers.loadingInProgress;
};

export const getError = (state) => {
  return state.prestoUsers.error;
};

export const getActionStatus = (state, props) => {
  const prestoUserId = getPrestoUserId(state, props);
  const status = state.prestoUsers.actionStatus.get(prestoUserId);
  return status;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

const getPrestoClusterIds = (state, props) => {
  if (!state.prestoUsers.prestoUsersPrestoClusterIds) {
    return null;
  }

  const prestoUserId = getPrestoUserId(state, props);
  return state.prestoUsers.prestoUsersPrestoClusterIds.get(prestoUserId);
};

const getPrestoClustersMap = (state) => {
  return state.prestoClusters.prestoClusters;
};

export const getPrestoClustersForPrestoUser = createSelector(
  getPrestoClusterIds,
  getPrestoClustersMap,
  (prestoClusterIds, prestoClusters) => {
    if (!prestoClusterIds) {
      return null;
    }

    if (prestoClusterIds.length === 0) {
      return [];
    }

    let results = prestoClusterIds.map((id) => {
      return removeNotFound(prestoClusters.get(id));
    });
    results = results.filter((pc) => {
      return !!pc;
    });
    return results;
  },
);

const getDataSourceIds = (state, props) => {
  if (!state.prestoUsers.prestoUsersDataSourcesIds) {
    return null;
  }

  const prestoUserId = getPrestoUserId(state, props);
  return state.prestoUsers.prestoUsersDataSourcesIds.get(prestoUserId);
};

const getDataSourcesMap = (state) => {
  return state.dataSources.dataSources;
};

export const getDataSourcesForPrestoUser = createSelector(
  getDataSourceIds,
  getDataSourcesMap,
  (dataSourceIds, dataSources) => {
    if (!dataSourceIds) {
      return null;
    }

    if (dataSourceIds.length === 0) {
      return [];
    }

    let results = dataSourceIds.map((id) => {
      return removeNotFound(dataSources.get(id));
    });
    results = results.filter((pc) => {
      return !!pc;
    });
    return results;
  },
);

export const getCanModify = createSelector(
  getPrestoUser,
  getPrestoClustersForPrestoUser,
  (prestoUser, prestoClusters) => {
    if (!prestoUser) {
      return false;
    }

    if (!prestoUser.prestoClusterCount) {
      return true;
    }

    if (!prestoClusters && prestoUser.prestoClusterCount) {
      return false;
    }

    if (prestoUser.prestoClusterCount) {
      const pcInvalidStatus = prestoClusters.filter((pc) => {
        return getSimpleStatus(pc.status) === simpleStatus.pending;
      });

      if (pcInvalidStatus.length > 0) {
        return false;
      }
    }

    return true;
  },
);

const getAllDataSourcesPrestoClusterIds = (state) => {
  return state.dataSources.dataSourcesPrestoClusterIds;
};

const getPrestoClustersForDataSourcesForPrestoUser = createSelector(
  getDataSourcesForPrestoUser,
  getAllDataSourcesPrestoClusterIds,
  getPrestoClustersMap,
  (dataSources, dataSourcesPrestoClusterIds, prestoClustersMap) => {
    if (!dataSources || !dataSourcesPrestoClusterIds || !prestoClustersMap) {
      return null;
    }

    const prestoClusterIds = new Set();
    dataSources.forEach((ds) => {
      const clusterIds = dataSourcesPrestoClusterIds.get(ds.dataSourceId);
      if (clusterIds) {
        clusterIds.forEach((id) => {
          prestoClusterIds.add(id);
        });
      }
    });

    const clusters = [];
    for (const prestoClusterId of prestoClusterIds) {
      const cluster = prestoClustersMap.get(prestoClusterId);
      if (cluster) {
        clusters.push(cluster);
      }
    }
    return clusters;
  },
);

export const getCanDelete = createSelector(
  getPrestoUser,
  getPrestoClustersForPrestoUser,
  getDataSourcesForPrestoUser,
  getPrestoClustersForDataSourcesForPrestoUser,
  (prestoUser, prestoClusters, dataSources, prestoClusters2) => {
    if (!prestoUser) {
      return false;
    }

    if (!prestoUser.prestoClusterCount && !prestoUser.dataSourceCount) {
      return true;
    }

    if (
      (!prestoClusters && prestoUser.prestoClusterCount) ||
      (!dataSources && prestoUser.dataSourceCount)
    ) {
      return false;
    }

    if (prestoUser.prestoClusterCount) {
      const pcInvalidStatus = prestoClusters.filter((pc) => {
        return getSimpleStatus(pc.status) === simpleStatus.pending;
      });

      if (pcInvalidStatus.length > 0) {
        return false;
      }
    }

    if (prestoClusters2 && prestoClusters2.length > 0) {
      const pcInvalidStatus = prestoClusters2.filter((pc) => {
        return getSimpleStatus(pc.status) === simpleStatus.pending;
      });

      if (pcInvalidStatus.length > 0) {
        return false;
      }
    }

    return true;
  },
);

export const getAlertBeforeDelete = createSelector(
  getPrestoUser,
  getDataSourcesForPrestoUser,
  getPrestoClustersForDataSourcesForPrestoUser,
  (prestoUser, dataSources, prestoClusters) => {
    if (
      !prestoUser ||
      !prestoUser.dataSourceCount ||
      (!dataSources && prestoUser.dataSourceCount)
    ) {
      return false;
    }

    if (
      dataSources.every((ds) => {
        return !ds.isAttached;
      })
    ) {
      return false;
    }

    if (prestoClusters && prestoClusters.length > 0) {
      const pcInvalidStatus = prestoClusters.filter((pc) => {
        return getSimpleStatus(pc.status) === simpleStatus.running;
      });

      if (pcInvalidStatus.length > 0) {
        return true;
      }
    }

    return false;
  },
);
