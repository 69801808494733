import Help from '../../Help';

const DataLakeIOCaching = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable Data IO Cache</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          Enabling data IO cache lets you cache the data from Amazon S3 to a
          local AWS EBS SSD drive per Presto worker which will improve your
          query performance.
        </Help.P>
        <Help.P>
          The size of the cache configured depends on the Presto worker AWS
          instance type. The total size of the EBS SSD volume configured is 3
          times the size of the memory of the AWS instance selected.
        </Help.P>
        <Help.P>
          If you have selected a &quot;d&quot; type EC2 instance then both data
          IO and intermediate result set cache will be enabled using the
          instance storage by default instead of EBS volumes.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const AhanaManagedHMS = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Create IBM watsonx.data-Managed Hive Metastore
      </Help.HelpHeader>
      <Help.P>
        Hive Metastore maps files in data lakes / object stores to databases,
        tables and columns queryable by SQL.
      </Help.P>
      <Help.P>
        Checking this box will provision a Hive Metastore that is pre-attached
        as a catalog to the Presto Cluster. The metadata for any tables created
        can be stored in this catalog. The metastore is also pre-configured with
        an S3 bucket where the data for tables defined is stored. You can
        pre-attach a Hive Metastore to every cluster.
      </Help.P>
      <Help.P>
        In order to preserve the Metadata, the Hive Metastore will still be
        running after the Presto Cluster has been stopped.
      </Help.P>
    </Help.HelpSection>
  );
};

const HMSInstanceType = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Hive Metastore AWS Instance Type</Help.HelpHeader>
        <Help.P>
          Hive Metastore maps files in data lakes / object stores to databases,
          tables and columns queryable by SQL.
        </Help.P>
        <Help.P>
          Select the{' '}
          <a
            href='https://aws.amazon.com/ec2/instance-types/'
            target='_blank'
            rel='noopener noreferrer'
          >
            AWS EC2 instance type
          </a>{' '}
          to be provisioned for the Hive Metastore instance. The recommended
          instance type is r5.4xlarge with 16 vCPUs and 128 GB memory.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            The available instance types are limited for community edition
            users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const AttachQueryLogTable = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>
        Attach cluster query log to IBM watsonx.data-managed Hive Metastore
      </Help.HelpHeader>
      <Help.P>
        The Presto query log is collected and stored in an S3 bucket. If this
        option is selected, IBM watsonx.data automatically integrates the query
        log as a table in the IBM watsonx.data-managed Hive Metastore. This
        feature is only available if the IBM watsonx.data-managed Hive Metastore
        is provisioned.
      </Help.P>
      <Help.P>
        Users can query the query log table via the Hive Metastore to get
        insights into queries run, such as memory usage and step execution time.
      </Help.P>
    </Help.HelpSection>
  );
};

const FragmentResultCaching = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable Intermediate Result Set Cache</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
        <Help.P>
          Enabling intermediate result set cache lets you cache partially
          computed results set on the worker&apos;s local AWS EBS SSD drive.
          This is to prevent duplicated computation upon multiple queries which
          will improve your query performance and decrease CPU usage.
        </Help.P>
        <Help.P>
          Intermediate result set cache is only beneficial for the workloads
          with aggregation queries.
        </Help.P>
        <Help.P>
          The size of the cache configured depends on the Presto worker AWS
          instance type. The total size of the EBS SSD volume configured is 2
          times the size of the memory of the AWS instance selected.
        </Help.P>
        <Help.P>
          If you have selected a &apos;d&apos; type EC2 instance then both data
          IO and intermediate result set cache will be enabled using the
          instance storage by default instead of EBS volumes.
        </Help.P>
      </Help.HelpSection>
    );
  };
};

const Prestissimo = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Provision Prestissimo Worker Nodes</Help.HelpHeader>
      <Help.P>
        Prestissimo is a native C++ Presto worker based on the Velox library.
      </Help.P>
      <Help.P>
        Checking this box will provision all Presto workers to be Prestissimo
        workers. This will restrict the supported catalogs for this cluster to
        Hive, Glue without Lake Formation, and TPCH.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  DataLakeIOCaching,
  AhanaManagedHMS,
  HMSInstanceType,
  AttachQueryLogTable,
  FragmentResultCaching,
  Prestissimo,
};

export default module;
