import { createApiAction } from '../../rayApi/actions';
import telemetry from '../../telemetry';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (prestoClusterId) => {
  return createApiAction(actionTypes.get, api.get, true, { prestoClusterId });
};

export const getDataSources = (prestoClusterId) => {
  return createApiAction(actionTypes.getDataSources, api.getDataSources, true, {
    prestoClusterId,
  });
};

export const patch = (
  prestoClusterId,
  { name, description, type, connection },
) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { prestoClusterId },
    { name, description, type, connection },
  );
};

export const remove = ({
  prestoClusterId,
  actionId,
  deleteQueryLogBucket,
  deleteHiveBucket,
}) => {
  return createApiAction(
    actionTypes.remove,
    api.remove,
    true,
    {
      prestoClusterId,
    },
    { deleteQueryLogBucket, deleteHiveBucket },
    { actionId, name: 'delete' },
  );
};

export const putRestart = (prestoClusterId, actionId) => {
  return createApiAction(
    actionTypes.putRestart,
    api.putRestart,
    true,
    {
      prestoClusterId,
    },
    null,
    { actionId, name: 'restart' },
  );
};

export const putStart = (prestoClusterId, actionId) => {
  return createApiAction(
    actionTypes.putStart,
    api.putStart,
    true,
    {
      prestoClusterId,
    },
    null,
    { actionId, name: 'start' },
  );
};

export const putStop = (prestoClusterId, actionId) => {
  return createApiAction(
    actionTypes.putStop,
    api.putStop,
    true,
    {
      prestoClusterId,
    },
    null,
    { actionId, name: 'stop' },
  );
};

export const patchWorkerNodes = (prestoClusterId, values, actionId) => {
  return createApiAction(
    actionTypes.patchWorkerNodes,
    api.patchWorkerNodes,
    true,
    {
      prestoClusterId,
    },
    {
      workerNodes: parseInt(values.workerNodes, 10),
    },
    { actionId, name: 'editWorkerNodes' },
  );
};

export const putDataSources = (prestoClusterId, values, actionId) => {
  return createApiAction(
    actionTypes.putDataSources,
    api.putDataSources,
    true,
    {
      prestoClusterId,
    },
    {
      dataSourceIds: values,
    },
    { actionId, name: 'editDataSources' },
  );
};

export const putCredentials = (prestoClusterId, body, actionInfo) => {
  return createApiAction(
    actionTypes.putCredentials,
    api.putCredentials,
    true,
    {
      prestoClusterId,
    },
    body,
    actionInfo,
  );
};

export const putExportLogs = (prestoClusterId, actionId) => {
  return createApiAction(
    actionTypes.putExportLogs,
    api.putExportLogs,
    true,
    {
      prestoClusterId,
    },
    null,
    { actionId, name: 'exportLogs' },
  );
};

export const putExportDebuggingInfo = (prestoClusterId, actionId) => {
  return createApiAction(
    actionTypes.putExportDebuggingInfo,
    api.putTriggerWorkflow,
    true,
    {
      prestoClusterId,
    },
    {
      workflowName: 'jstackJfrCollectAndTransfer',
      allNodes: true,
    },
    { actionId, name: 'exportDebuggingInfo' },
  );
};

export const putTriggerOnDemandPrestoDebugging = (
  prestoClusterId,
  body,
  actionId,
) => {
  return createApiAction(
    actionTypes.putTriggerOnDemandPrestoDebugging,
    api.putTriggerWorkflow,
    true,
    { prestoClusterId },
    body,
    { actionId, name: 'heapDumpCollectTransfer' },
  );
};

export const submitCredentials = (prestoClusterId, values, actionId) => {
  return {
    type: actionTypes.submitCredentials,
    payload: {
      prestoClusterId,
      values,
      actionInfo: { actionId, name: 'editCredentials' },
    },
  };
};

export const putAutoScalingPolicy = (prestoClusterId, values, actionId) => {
  let finalValues = {};

  if (values.type === 'idleCostSavings') {
    finalValues = {
      ...values,
      workerNodes: parseInt(values.workerNodes, 10),
      idleTime: parseInt(values.idleTime, 10),
    };
  } else {
    finalValues = {
      ...values,
      minWorkerNodes: parseInt(values.minWorkerNodes, 10),
      maxWorkerNodes: parseInt(values.maxWorkerNodes, 10),
      stepSize: parseInt(values.stepSize, 10),
      idleTime: parseInt(values.idleTime, 10),
    };
  }

  return createApiAction(
    actionTypes.putAutoScalingPolicy,
    api.putAutoScalingPolicy,
    true,
    {
      prestoClusterId,
    },
    finalValues,
    { actionId, name: 'editAutoScalingPolicy' },
  );
};

export const putGroupedConfig = (prestoClusterId, values, actionId) => {
  return createApiAction(
    actionTypes.putGroupedConfig,
    api.putGroupedConfig,
    true,
    {
      prestoClusterId,
    },
    values,
    { actionId, name: 'editGroupedConfig' },
  );
};

export const putEnableSpot = (prestoClusterId, values, actionId) => {
  const enableASGCapacityRebalance =
    values.enableASGCapacityRebalance === undefined
      ? true
      : values.enableASGCapacityRebalance;
  return createApiAction(
    actionTypes.putEnableSpot,
    api.putEnableSpot,
    true,
    {
      prestoClusterId,
    },
    { ...values, enableASGCapacityRebalance },
    { actionId, name: 'editEnableSpot' },
  );
};

export const putPrestoUsers = (prestoClusterId, values, actionId) => {
  return createApiAction(
    actionTypes.putPrestoUsers,
    api.putPrestoUsers,
    true,
    { prestoClusterId },
    {
      prestoUserIds: values,
    },
    { actionId, name: 'editPrestoUsers' },
  );
};

const actionSanitizer = (action) => {
  let sanitizedAction = action;
  if (action.type === actionTypes.submitCredentials) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        values: {
          ...action.payload.values,
          password: telemetry.sanitize(action.payload.values.password),
          password2: telemetry.sanitize(action.payload.values.password2),
        },
      },
      sanitized: true,
    };
  } else if (action.type === actionTypes.putCredentials.request) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        body: action.payload.body.map((c) => {
          return {
            ...c,
            password: telemetry.sanitize(c.password),
          };
        }),
      },
      sanitized: true,
    };
  } else if (
    action.type === actionTypes.putCredentials.pending ||
    action.type === actionTypes.putCredentials.accepted ||
    action.type === actionTypes.putCredentials.success ||
    action.type === actionTypes.putCredentials.failure ||
    action.type === actionTypes.putCredentials.abort
  ) {
    sanitizedAction = {
      ...action,
      payload: {
        ...action.payload,
        requestBody: action.payload.requestBody.map((c) => {
          return {
            ...c,
            password: telemetry.sanitize(c.password),
          };
        }),
      },
      sanitized: true,
    };
  }
  return sanitizedAction;
};

telemetry.actionSanitizer.registerActionSanitizer(
  'prestoClusters',
  actionSanitizer,
);
