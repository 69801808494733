import { useCallback, useContext, useMemo } from 'react';
import { bool, string, shape, number } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import { useEnableFeature } from '../../utils/featureHooks';
import {
  ClusterSettingsConcurrencyDiv,
  ClusterSettingsConcurrencyDivs,
} from '../../PrestoClusterAdd/components';
import PrestoClusterInfo from './PrestoClusterInfo';
import PendingStatus from './PendingStatus';
import PageHelp from './PageHelp';

const display = {
  header: 'Workload Profile',
  cancelButton: 'Cancel',
  updateButton: 'Update',
  savingButton: 'Updating...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  concurrencyLowValue: 'low',
  concurrencyLowLabel: 'Low Concurrency',
  concurrencyLowDetails:
    'Low concurrency is useful for clusters that run a limited number of queries or a few large, complex queries. It also supports bigger and heavier ETL jobs.',
  concurrencyHighValue: 'high',
  concurrencyHighLabel: 'High Concurrency',
  concurrencyHighDetails:
    'High concurrency is better for running multiple queries at the same time. For example, dashboard and reporting queries or A/B testing analytics, etc.',
  featureUnavailable: 'This feature is not available for this cluster.',
};

const concurrencyMode = (label, details) => {
  return () => {
    return (
      <ClusterSettingsConcurrencyDiv>
        <div>{label}</div>
        <div className='details'>{details}</div>
      </ClusterSettingsConcurrencyDiv>
    );
  };
};

const concurrencyValues = [
  {
    value: display.concurrencyLowValue,
    label: display.concurrencyLowLabel,
    component: concurrencyMode(
      display.concurrencyLowLabel,
      display.concurrencyLowDetails,
    ),
  },
  {
    value: display.concurrencyHighValue,
    label: display.concurrencyHighLabel,
    component: concurrencyMode(
      display.concurrencyHighLabel,
      display.concurrencyHighDetails,
    ),
  },
];

const ChangeGroupedConfigForm = ({
  concurrency,
  name,
  status,
  actionStatus,
  prestoClusterId,
  submitSuccess,
  usePop,
}) => {
  const enableFeature = useEnableFeature();
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const canChangeGroupedConfig =
    enableFeature && status === PrestoClusters.constants.status.active;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editGroupedConfig';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {!canChangeGroupedConfig && (
            <Banner scrollIntoView>
              <PendingStatus name={name} status={status} />
            </Banner>
          )}
          {showActionStatusError && (
            <Banner scrollIntoView title={actionStatus.error} />
          )}
          <PrestoClusterInfo name={name} />
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.header}</h2>
            </Page.BoxHeader>
            <Page.FormContent>
              <ClusterSettingsConcurrencyDivs>
                <Form.RadioInputs
                  name='concurrency'
                  values={concurrencyValues}
                  defaultValue={concurrency}
                  disabled={submitInProgress || !canChangeGroupedConfig}
                />
              </ClusterSettingsConcurrencyDivs>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
            />
            <Form.Submit
              label={display.updateButton}
              disabled={submitInProgress || !canChangeGroupedConfig}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

ChangeGroupedConfigForm.defaultProps = {
  actionStatus: undefined,
  concurrency: null,
  name: undefined,
  status: null,
  submitSuccess: false,
  usePop: false,
};

ChangeGroupedConfigForm.propTypes = {
  concurrency: string,
  name: string,
  status: string,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  prestoClusterId: string.isRequired,
  submitSuccess: bool,
  usePop: bool,
};

export default ChangeGroupedConfigForm;
